<template>
  <div class="wrapper">
    <div class="page-header success-page">
      <div
        class="page-header-image"
        style="background-image: url('img/ill/404.svg');"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="title">Firma finalizada</h1>
            <p class="lead">Documentos firmados</p>
            <h4 class="description text-default">
              Has completado la firma de la documentación. Recibirás una notiifcación ccon un enlace para acceder a los documentos firmados cuando quieraas.
            </h4>
          </div> 

        </div>
        <div claass="row">
                    <div class=" col-md-6 text-center">
                      <base-button
                    type="primary"
                    class="my-4 "
                    style="float:right"
                    @click="viewDocs"
      
                    >Ver Documentos Firmados</base-button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
 
export default {
    components: {
    },
    data() {
        return {
            currentPage: 0,
            pageCount: 0,
        }
    },
    methods:{
            viewDocs: function() {
      this.$router.push({
        path: "/pdfalreadysigned",
      });
    },
    }
}

</script>
<style></style>
