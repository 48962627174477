<template>
  <div>
    <header class="header-5 sticky-top ">
      <base-nav
        class="navbar-main  bg-white sticky-top "
        type=""
        effect="white"
        expand
      >
        <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
          <a href="javascript:;" class="navbar-brand text-primary"
            >SIGN AND RUN</a
          >
        </router-link>

        <div class="row" slot="content-header" slot-scope="{ closeMenu }">
          <div class="col-6 collapse-brand">
            <a href="javascript:void(0)">
              <img src="img/brand/blue.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <close-button @click="closeMenu"></close-button>
          </div>
        </div>

        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link" href="javascript:;" @click="gotoFeature2()">
              Inicio
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="gotoCrearEventos()" href="javascript:;">
              Crear Evento
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" @click="gotoEventos" href="javascript:;">
              Eventos
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="gotoContact()" href="javascript:;">
              Contacto
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="gotoHelp" href="javascript:;">
              Ayuda
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" @click="gotoPerfil" href="javascript:;">
              Mi Perfil
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="gotoLogout" href="javascript:;">
              Salir
            </a>
          </li>
        </ul>
    
      </base-nav>
    </header>
  </div>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
  },

  data() {
    return {
      maxpart: 0,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      v1: "cccccccc",
      nombre: "",
      selected: 0,
      zzz: "xx",
    };
  },

  beforeMount() {},

  methods: {
    gotoContact: function(uuid) {
      this.$router.push({
        path: "/contact-us",
        query: { id: uuid },
      });
    },
    gotoSections: function(uuid) {
      this.$router.push({
        path: "/sections",
        query: { id: uuid },
      });
    },
    gotoPaginated: function() {
      this.$router.push({
        path: "/paginated",
      });
    },
    gotoEventos: function() {
      this.$router.push({
        path: "/listaeventos2",
      });
    },
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },
    gotoFeature2: function(uuid) {
      this.$router.push({
        path: "/feature2",
        query: { id: uuid },
      });
    },
    gotoLogout: function() {
      this.$store.commit("setIsLogged", false);

      this.$router.push({
        path: "/",
      });
    },

    gotoPerfil: function() {
      this.$router.push({
        path: "/perfil",
      });
    },
  },
};
</script>
<style></style>
