<template>

  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
  
  <base-button tag="a" @click="gotoParti(evento.id)" class="btn btn-white"
    >Quiero Pre-Registrarme</base-button
  >

  <div>
    <card class="col-10 center">
      <template slot="image">
        <img
          class="card-img-top cover "
          :src="evento.imagen1" height="300px"  width="300px"
          alt="Image placeholder"
        />
      </template>
      <template slot="body">
        <div  class="row">
          <div class="col-md-6">
        <h5 class="h2 card-title mb-0">
          {{ evento.nombre }} en {{ evento.localizacionnombre }}
        </h5>

          </div>
                    <div class="col-md-3"></div>

          <div class="col-md-3">
            <section>
            <span > <span class="small">Organizador: </span>{{evento.organizador.nombre}}</span> 
            <img :src="evento.organizador.imagen1"  width="200px"/>
            </section>
            </div>

        </div>
        <small class="text-muted">Fecha : {{evento.fmtfecha}}  Inscripciones hasta {{evento.fmtfininscripcion}}</small>
        <p class="card-text mt-4">
          {{evento.descripcion}}
        </p>
              <base-button
                nativeType="reset"
                outline
                @click="gotoParti(evento.id)"
                type="primary"
                >Quiero Registrarme</base-button>      
                </template>
    </card>
               <base-button  class="ml-lg-4"
                nativeType=""
                outline
                @click="gotoListEventos"
                type="primary"
                >Volver</base-button>      
                
  </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option,
} from "element-ui";
import Spinner from "../components/Spinner.vue";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";
import moment from "moment";

export default {
  mixins: [clientPaginationMixin],

  components: {
    Spinner,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      filter: "",
      evento: {},
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      propsToSearch: ["nombre", "fecha", "estado"],
      colores: "green",
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/geteventopublic?evento=" +
        this.uuid;
      self.$http.get(url).then(
        function(success) {
          self.evento = success.data;
          self.evento.fmtfecha = moment(self.evento.fecha).format("DD/MM/YYYY");
          self.evento.fmtfininscripcion = moment(
            self.evento.fininscripcion
          ).format("DD/MM/YYYY");
          self.oldevento = JSON.parse(JSON.stringify(success.data));
          self.mododatos = self.evento.modogeneracion;

          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del evento  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },

    gotoParti: function(uuid) {
      this.$router.push({
        path: "/crearpartipublic/" + uuid,
        query: { id: uuid },
      });
    },
    gotoListEventos: function(uuid) {
      this.$router.push({
        path: "/listaeventoslibres",
      });
    },    
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },
  },
};
</script>

<style scoped>
.cover {
  object-fit: cover;
  width: 300px;
  height: 500px;
}
</style>
