import axios from 'axios';
import {store} from './../store'


const API_URL = process.env.VUE_APP_BASE_URI + 'api/token/';
const isAuthenticated = () => store.getters.validUser;

class AuthService {
  login(user) {
      let ss = store.state.token
    return axios
      .post(API_URL , {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('user', JSON.stringify(response.data));

      //    _this.$store.commit("isLogin", false);
          store.commit("setToken", response.data.access);
            console.log(isAuthenticated+ ' ' +ss)

          store.commit("setValiduser", user.username);
          store.commit("setValidpassword", user.password);
          //this.$router.push({path: '/myMachines'})
          //his.posts = data;

 

        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();