<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">


      </div>
    </div>
    <div class="bg-secondary">
      <div class="container bg-white card mb-0">
        <div class="row">

          <div class="col-md-12 ml-auto">
            <div class="section">
              <div class="tab-content">
                

                <div >
                  <header>
                    <h2 class="text-uppercase">Aportar documentación</h2>
                  </header>
                  <hr class="line-primary" />
                  
                  <span class="mb-4">Es necesario que aportes los siguientes documentos antes de continuar.</span>
                                    <br />

                  <table class="table align-items-center mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Documento Aportado</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(doc,i) in docsUpload" :key="i">
                        <th>
                          <span class="d-block">{{doc.docName}}</span>
                        </th>
                        <th scope="row">
                          <img
                            alt="Image" height="150px" 
                            :src="doc.urlimage + '?rnd=' + rndnum"
                            class=""
                          />
                        </th>

                        <td class="text-center">
                          <label class="file-select">
                            <div class="select-button small" style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir documento</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = i"
                              @change="handleFileChange"
                            />
                          </label>
                        </td>
                      </tr>

                      

                                    
                    </tbody>
                  </table>

                </div>

             

          

               
              </div>
            </div>
          </div>
        </div>
        <div  class="my-4 col-md-12 align-items-end">
                    <base-button v-if="canContinue"
        type="primary"
        class="my-4 col-md-4 align-items-end"
        style="float:right"
        @click="gotoSignature"
       
        >Continuar</base-button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
//import ImageUpload from "@/components/ImageUpload";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import ChoicesSingle from "@/components/SingleSelect";
import Tags from "@/components/Tags";
import BaseAlert from "@/components/BaseAlert";
import UserService from "../services/user.service";
import axios from "axios";
import authHeader from "../services/auth-header";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
//import VueCoreVideoPlayer from 'vue-core-video-player';


export default {
    name: 'app',

  bodyClass: "account-settings",
  components: {
  //  ImageUpload,

    
 
  },
  props: {
    value: File,
  },

  data() {
    return {
      accountTab: "Mis Datos",
      currentOrg: {},
      selected: 2,
      tipoimagen: "",
      oldOrganizador: {},
      gender: [
        { id: 1, text: "Male" },
        { id: 2, text: "Female" },
      ],
      months: [],
      days: [],
      years: [],
      languages: [],
      questions: [],
      docsUpload: [],
      switches: {
        off: false,
        on: true,
      },
      radio: {
        radio1: "radio1",
      },
      rndnum: 0,
      canContinue: false
    };
  },
  mounted() {
     this.docsUpload = this.$store.getters.documentsUpload;
     this.loadData();
  },
  methods: {
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;

    },
    loadData() {
      const self = this;
      this.canContinue = true;
      for( var i = 0; i < this.docsUpload.length; i++){
        if( this.docsUpload[i].documentUuid == null ){
          this.canContinue = false;
          //continue;
        }
        if( this.docsUpload[i].documentUuid != null ){
          this.getDocumentbypos(this.docsUpload[i].documentUuid, i)
          this.docsUpload[i].urlimage = `${process.env.VUE_APP_FIRMA_BASEURL}${"getpage/248/"}${
            this.$store.getters.token
          }${"/"}${this.docsUpload[i].documentUuid}${"/1"}`
        }
        else{
          this.docsUpload[i].urlimage = "/img/placeholder.jpg";
        }
      }

    },
    getDocumentbypos(documentuuid, pos) {
      const _this = this;
      this.isLoading = true;
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      this.$http
        .get(
          `${process.env.VUE_APP_FIRMA_BASEURL}${"getdocumentbyuuidb64/"}${
            this.$store.state.opuuid
          }${"/"}${documentuuid}`
        )
        .then(
          (responseSuccess) => {
            if (pos == 0) {
              _this.pdfsrc = `data:application/pdf;base64,${responseSuccess.data}`;
            }
            _this.docsUpload[
              pos
            ].b64 = `data:application/pdf;base64,${responseSuccess.data}`;
            _this.canViewPDF = true;
            _this.isLoading = false;
            //this.showPdf(0);
          },
          (responseError) => {
            _this.isLoading = false;
            _this.$store.commit("loading", false);
          }
        );
    },
    updateImagen(imagen, docidx, tipo, code) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(imagen);
      reader.onload = () => {
        self.readerimg = reader.result;
        var datos = self.readerimg;
        if (self.readerimg.split(",")[0].indexOf("base64") >= 0) {
          datos = self.readerimg.split(",")[1];
        }
        
        var data = {
          documentName: self.docsUpload[docidx].docName,
          operationUuid: self.$store.state.opuuid,
          mimeType: "application/png",
          reference: self.docsUpload[docidx].reference,
          contentB64: datos,
          originalname: imagen.name
        };
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;

      this.$http
        .post(
          process.env.VUE_APP_FIRMA_BASEURL +
            "uploadcustomerfileb64/" +
            this.$store.state.opuuid ,
          data
        )
        .then(
          (resp) => {
            if( self.docsUpload[docidx].documentUuid == null){
            self.docsUpload[docidx].documentUuid = resp.data.documentUuid;
            self.docsUpload[docidx].urlimage = `${process.env.VUE_APP_FIRMA_BASEURL}${"getpage/248/"}${
            self.$store.getters.token
          }${"/"}${self.docsUpload[docidx].documentUuid}${"/1"}`
            }

            swal.fire({
              title: "Fichero añadido",
              text: "Se ha añadido el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "success",
            });
            self.isLoading = false;
            self.rndnum= +new Date();
            self.canContinue = true;
            for( var j = 0; j < self.docsUpload.length; j++){
              if( self.docsUpload[j].documentUuid == null){
                self.canContinue = false;
                break;
              }
            }
           // self.loadData();
          },
          (error) => {
            swal.fire({
              title: "Fichero no añadido",
              text: "Error al añadir el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-error",
              icon: "error",
            });
            self.isLoading = false;
            self.readerxls = "";
          }
        );
      };
    },
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
      this.updateImagen(e.target.files[0], this.tipoimagen);
    },
            gotoSignature: function() {
                this.$store.commit("setDocumentsCompleted", true);

      this.$router.push({
        path: "/pdfsigner",
        
      });
  },
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2ea169;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
