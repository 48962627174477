<template>
  <div class="wrapper">
    <div class="section-shaped skew-separator skew-mini">
      <div class="page-header page-header-mini header-filter">
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary">
      <div class="container">
        <div class="row">
          <h4>Firma completada</h4>
        </div>
        <div class="row">
          <div
            class="col-md-4"
            v-for="(item, index) in documentDatas"
            :key="index"
          >
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >{{ item.documentLabel }}
            </small>

            <div class="form-group mb-0">
              <img
                src="@/assets/pdf.png"
                width="80"
                style="background-color: gray"
                @click="showPdf(index)"
              />
            </div>
            <label
              class="d-block text-uppercase  font-weight-bold mb-3 ml-2"
              v-if="item.state == 2"
              style="color: green;"
              >Firmado</label
            >
          </div>
        </div>
        <div class="row mt-5 bold">
          <small class="d-block   mb-3 ">Documento seleccionado: </small>
          <small class="d-block font-weight-bold text-uppercase   mb-3 ml-3">
            {{ documentName }}</small
          >
        </div>

        <div class="row">
          <div class="col-md-10">
            Página: {{ currentPage }} / {{ pageCount }}


        <v-btn class="btn btn-primary btn-sm" @click="pageprev" style="font-size:10"
          >Pag-</v-btn>          

        <v-btn class="btn btn-primary btn-sm" @click="pagenext" 
          >Pag+</v-btn>

        <button
          class="btn btn-primary btn-sm"
          :disabled="zoom == 100"
          @click="zoomminus"
        >-</button>
         {{ zoom }}%


         <button class="btn btn-primary btn-sm" @click="zoomplus">
          +
        </button>

            <button class="btn btn-primary btn-sm" @click="rotate += 90">
              &#x27F3;
            </button>
            <button class="btn btn-primary btn-sm" @click="rotate -= 90">
              &#x27F2;
            </button>
            <button class="btn btn-primary btn-sm" @click="downloadDocumentbypos">
              Descargar
            </button>

            <div id="pdf11" ref="pdf112" style="width: 100%" class="mt-3">
              <div
                v-if="loadedRatio > 0 && loadedRatio < 1"
                style="background-color: green; color: white; text-align: center"
                :style="{ width: loadedRatio * 100 + '%' }"
              >
                {{ Math.floor(loadedRatio * 100) }}%
              </div>

              <pdf
                ref="pdf"
                style="border: 1px solid red"
                :src="pdfsrc"
                :page="page"
                :rotate="rotate"
                @password="password"
                @progress="loadedRatio = $event"
                @error="error"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                @link-clicked="page = $event"
                @loaded="drawRect"
                            :style="pdfstyle"

              ></pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>



    </div>
    <div class="text-center col-lg-10">
 <!--     <base-button
        nativeType="reset"
        style="float:left"
        class="mt-3"
        outline
        @click="gotoEventos()"
        type="primary"
        >Volver</base-button
      > -->
      <span class="col-md-6"></span>



    </div>

    <div class="col-md-4">
      <modal :show.sync="modalerror">
        <div class="modal-content bg-gradient-danger">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">
              Ha ocurrido un error
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="heading mt-4">Error al crear participante!</h4>
              <p></p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-white"
              @click="modalerror = false"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-link text-white ml-auto"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </modal>
    </div>
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import pdf from "vue-pdf-signature";

//import BaseDropdown from "@/components/BaseDropdown";
import ChoicesSingle from "@/components/SingleSelect";
import UserService from "../services/user.service";
import Modal from "@/components/Modal";
import VueSignaturePad from "vue-signature-pad";
import Testfirma from "./Testfirma.vue";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

var loadingTask = pdf.createLoadingTask(
  "http://localhost:8081/conduccionperf1.pdf"
);

export default {
  name: "CrearRunner",
  props: ["id"],
  components: {
    pdf,
    Modal,
  },
  data() {
    return {
      dates: {
        simple: "",
        range: "2020-07-17 to 2020-07-19",
        datetime: "2020-07-31 12:00",
        inicioins: "",
        finins: "",
      },
            pdfstyle: "width: 100%",

      zoom: 100,
      modal0: false,
      modal1: false,
      modalerror: false,
      maxpart: 0,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      v1: "cccccccc",
      nombre: "",
      email: "",
      nif: "",
      apellido1: "",
      apellido2: "",
      selected: 0,
      modoseguro: 0,
      rfid: "",
      dorsal: "",
      evento: {},
      participacion: {},
      campos: [],
      optionsseguro: [
        { id: 0, text: "SIN SEGURO" },
        { id: 1, text: "SEGURO ANUAL" },
        { id: 2, text: "LICENCIA" },
      ],
      //   src: loadingTask,
      src: null,
      isLoading: false,
      numPages: undefined,
      readerpdf: "",
      readerpdfadic: "",
      readerxls: "",
      readerimg: "",
      currentPage: 0,
      pageCount: 0,
      rotate: 0,
      page: 1,
      currentop: null,
      documentb64: [],
      documentName: "",
      documentNames: [],
      signaturePos: [],
      documentData: [],
      documentDatas: [],
      canSign: false,
      customerid: 0,
      pdfsrc: null,
      theme: "light",
      button: {
        title: "",
        class: "btn btn-disabled",
      },
      canvaspdf: null,
      vueCanvas: null,
      formData: {},
      selectedPos: 0,
      allSigned: false,
      opAlreadySigned: false
    };
  },

  mounted() {
    var parameters = this.$route.query;
    console.log(parameters);
    var opuuid = parameters["op"];
    console.log(this.$store.state.opuuid);
    const _this = this;
    // this.src.promise.then((pdf) => {
    //   _this.numPages = pdf.numPages;
    //   console.log(this.$refs["pdf112"])
    // });
    this.canvaspdf = this.$refs["pdf112"].children[0].children[0];
    var ctx = this.canvaspdf.getContext("2d");
    //var c = document.getElementById("c");
    //var ctx = c.getContext("2d");

    this.vueCanvas = ctx;
    ctx.fillStyle = "blue";
    ctx.fillRect(0, 0, 500, 500);
    this.canvaspdf.addEventListener("click", this.getClickPosition, false);
    //  this.getDocumentsData();

    this.getDocuments();
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },
    loadData() {
      const self = this;
      self.isLoading = true;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/laptracker/laptrackerevento/?id=" +
        this.uuid;
      self.$http.get(url).then(
        function(success) {
          self.evento = success.data.results[0];

          if (self.evento.datosadicionales != null) {
            self.campos = self.evento.datosadicionales.split(",");
          }
          self.isLoading = false;
          self.formData = {};
          for (var i = 0; i < self.campos.length; i++) {
            var newfield = {};
            newfield.name = self.campos[i];
            newfield.class = "form-control";
            newfield.column = self.campos[i];
            newfield.type = "text";
            newfield.value = "";
            newfield.placeholder = "";
            self.form.push(newfield);
          }

          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del evento  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    getDocuments() {
      const _this = this;
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      var url = process.env.VUE_APP_FIRMA_BASEURL;
      this.isLoading = true;

      this.$http
        .get(`${url}${"getoperationbyuuid/"}${this.$store.state.opuuid}`)
        .then(
          (responseSuccess) => {
            _this.currentoperation = responseSuccess.data;

            if( _this.currentoperation == 2){
              _this.opAlreadySigned = true;
            }
            _this.customerid = _this.currentoperation.customers[0].id;
            _this.getDocumentsData();

            //  this.canViewPDF = true;
            _this.isLoading = false;
          },
          (responseError) => {
            _this.isLoading = false;
            swal.fire({
              title: "Error!",
              text: "No se ha podido recuperar el documento .",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "warning",
            });
          }
        );
    },
    getDocumentsData() {
      const _this = this;
      this.documentDatas = [];
      this.isLoading = true;
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      var url = process.env.VUE_APP_FIRMA_BASEURL;
      this.$http
        .get(`${url}${"listdocstosignwithpos/"}${this.$store.state.opuuid}`)
        .then(
          (responseSuccess) => {
            _this.currentop = responseSuccess.data;
            _this.allSigned = true;
            for (var i = 0; i < _this.currentop.length; i++) {
              var docData = {};
              docData.docName = _this.currentop[i].documentLabel;
              docData.documentUuid = _this.currentop[i].documentUuid;
              docData.reference = _this.currentop[i].reference;
              docData.documentLabel = _this.currentop[i].documentLabel;
              docData.state = _this.currentop[i].state;
              if (docData.state == 0) {
                _this.allSigned = false;
              }
              for (var j = 0; j < _this.currentop[i].positions.length; j++) {
                if (
                  _this.customerid == _this.currentop[i].positions[j].customerid
                ) {
                  var signPos = {
                    x: _this.currentop[i].positions[j].x,
                    y: _this.currentop[i].positions[j].y,
                    width: _this.currentop[i].positions[j].width,
                    height: _this.currentop[i].positions[j].height,
                    page: _this.currentop[i].positions[j].pageNumber,
                    customerid: _this.currentop[i].positions[j].customerid,
                    signboxName: _this.currentop[i].positions[j].signboxName,
                  };
                }
                _this.signaturePos.push(signPos);
                docData.signpos = signPos;
                if( i == 0){
                        _this.canSign = docData.state != 2 ? true : false;
                  _this.documentName = docData.documentLabel;
                    _this.selectedPos = i;
                }
              }

              _this.documentDatas.push(docData);
            }
            _this.canViewPDF = true;
            _this.$store.commit("loading", false);
            for (var k = 0; k < _this.documentDatas.length; k++) {
              _this.getDocumentbypos(_this.documentDatas[k].documentUuid, k);
            }
          },
          (responseError) => {
            this.$store.commit("loading", false);
          }
        );
    },
    getDocumentbypos(documentuuid, pos) {
      const _this = this;
      this.isLoading = true;
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      this.$http
        .get(
          `${process.env.VUE_APP_FIRMA_BASEURL}${"getdocumentbyuuidb64/"}${
            this.$store.state.opuuid
          }${"/"}${documentuuid}`
        )
        .then(
          (responseSuccess) => {
            if (pos == 0) {
              _this.pdfsrc = `data:application/pdf;base64,${responseSuccess.data}`;
            }
            _this.documentDatas[
              pos
            ].b64 = `data:application/pdf;base64,${responseSuccess.data}`;
            _this.canViewPDF = true;
            _this.isLoading = false;
            //this.showPdf(0);

          },
          (responseError) => {
            _this.isLoading = false;
            _this.$store.commit("loading", false);
          }
        );
    },

    downloadDocumentbypos() {
      const _this = this;
      this.isLoading = true;
      var documentuuid = this.documentDatas[this.selectedPos].documentUuid;
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      this.$http
        .get(
          `${process.env.VUE_APP_FIRMA_BASEURL}${"downloaddocumentbyuuid/"}${
            this.$store.state.opuuid
          }${"/"}${documentuuid }`, {responseType: 'arraybuffer'}
        )
        .then(
          (response) => {
      let blob = new Blob([response.data], { type: 'application/pdf' }),
        url = window.URL.createObjectURL(blob)

      window.open(url) 
            _this.isLoading = false;
            //this.showPdf(0);

          },
          (responseError) => {
            _this.isLoading = false;
            _this.$store.commit("loading", false);
          }
        );
    },    
    getDocument(document) {
      const _this = this;
      this.$store.commit("loading", true);
      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;
      this.$http
        .get(
          `${process.env.VUE_APP_FIRMA_BASEURL}${"getdocumentbyuuidb64/"}${
            this.$store.state.opuuid
          }${"/"}${document.documentUuid}`
        )
        .then(
          (responseSuccess) => {
            _this.pdfsrc = `data:application/pdf;base64,${responseSuccess.data}`;
            _this.canViewPDF = true;
            _this.$store.commit("loading", false);
            this.showPdf(0);
          },
          (responseError) => {
            _this.$store.commit("loading", false);
          }
        );
    },
    signDocument() {
      var docuuid = this.documentDatas[this.selectedPos].documentUuid;
      var docdata = this.documentDatas.find((item) => {
        return item.documentUuid == docuuid;
      });
      var imgdata = this.$store.state.graphoimg.split(",")[1];
      var signData = {
        signboxName: docdata.signpos.signboxName,
        b64img: imgdata,
        signaturetype: "",
        documentUuid: docuuid,
        operationUuid: this.$store.state.opuuid,
      };

      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;

      this.$http
        .post(
          process.env.VUE_APP_FIRMA_BASEURL +
            "addsigntodocument/" +
            this.$store.state.opuuid +
            "/" +
            docuuid,
          signData
        )
        .then(
          (response) => {
            if (response.data) {
              console.log(response);
              swal.fire(
                {
                  title: `Evento Cancelado`,
                  text: `Datos de evento eliminados...`,
                  buttonsStyling: false,
                  confirmButtonClass: "btn btn-primary",
                },
                (error) => {
                  swal.fire({
                    title: `Error Cancelado`,
                    text: `Se ha producido un error cancelando el evento.`,
                    buttonsStyling: false,
                    confirmButtonClass: "btn btn-primary",
                  });
                  return Promise.reject(error);
                }
              );

              this.$router.push({
                path: "/listaeventos2",
              });
            }
          },
          (error) => {
            self.modalerrorr = true;

            return Promise.reject(error);
          }
        );
    },
    closesign() {
      this.modal0 = false;
      if (this.$store.state.graphoimg != null) {
        var image = new Image();
        const _this = this;
        image.onload = function() {
          _this.vueCanvas.drawImage(
            image,
            _this.signaturePos[_this.selectedPos].x,
            _this.vueCanvas.canvas.height -
              _this.signaturePos[_this.selectedPos].y -
              _this.signaturePos[_this.selectedPos].height,
            _this.signaturePos[_this.selectedPos].width,
            _this.signaturePos[_this.selectedPos].height
          );
        };
        image.src = this.$store.state.graphoimg;
      }
    },
    confirmSignature() {
      var docuuid = this.documentDatas[this.selectedPos].documentUuid;
      var docdata = this.documentDatas.find((item) => {
        return item.documentUuid == docuuid;
      });
      var imgdata = this.$store.state.graphoimg.split(",")[1];
      var otpValue = {
        otpType: "FIRMA",
        documentUuid: docuuid,
        operationUuid: this.$store.state.opuuid,
      };

      this.$http.defaults.headers.common.Authorization = this.$store.getters.token;

      this.$http
        .post(
          process.env.VUE_APP_FIRMA_BASEURL +
            "createotp2/" +
            this.$store.state.opuuid,
          otpValue
        )
        .then(
          (response) => {
            if (response.status < 300) {
              console.log(response);

              this.$router.push({
                path: "/otpinput",
              });
            }
          },
          (error) => {
            self.modalerrorr = true;

            return Promise.reject(error);
          }
        );
    },

    gotoEventos: function(uuid) {
      this.drawRect();
    },
    drawRect() {
      // clear canvas
      const _this = this;
      setTimeout(function() {
        _this.vueCanvas.fillStyle = "rgba(0,0,225,0.4)";
        _this.vueCanvas.strokeStyle = "#FF0000";
        _this.vueCanvas.strokeRect(
          _this.signaturePos[_this.selectedPos].x,
          _this.vueCanvas.canvas.height -
            _this.signaturePos[_this.selectedPos].y -
            _this.signaturePos[_this.selectedPos].height,
          _this.signaturePos[_this.selectedPos].width,
          _this.signaturePos[_this.selectedPos].height
        );
        _this.vueCanvas.fillRect(
          _this.signaturePos[_this.selectedPos].x,
          _this.vueCanvas.canvas.height -
            _this.signaturePos[_this.selectedPos].y -
            _this.signaturePos[_this.selectedPos].height,
          _this.signaturePos[_this.selectedPos].width,
          _this.signaturePos[_this.selectedPos].height
        );
        // draw rect
      }, 2000);
    },
    getClickPosition(e) {
      var xPosition = e.clientX;
      var yPosition = e.clientY;
      if (
        sampleRectX <= xPosition &&
        sampleRectX + sampleRectW >= xPosition &&
        sampleRectY <= yPosition &&
        sampleRectY + sampleRectH >= yPosition
      ) {
        if (lose === true) {
          playAgain = true;
        }
      }
    },
    addWidth() {
      this.rectWidth += 10;
      this.drawRect();
    },
    subWidth() {
      this.rectWidth -= 10;
      this.drawRect();
    },
    showPdf(index) {
      this.pdfsrc = this.documentDatas[index].b64;
      this.canSign = this.documentDatas[index].state != 2 ? true : false;
      this.documentName = this.documentDatas[index].documentLabel;
      this.selectedPos = index;
    },
        pagenext() {
      this.currentPage++;
      if (this.currentPage > this.pageCount) this.currentPage = this.pageCount;
    },
    pageprev() {
      this.currentPage--;
      if (this.currentPage < 1) this.currentPage = 1;
    },
    zoomplus() {
      this.zoom+=20;
      this.pdfstyle ="width:" + this.zoom+"% "
    },
    zoomminus() {
      this.zoom-=20;
      this.pdfstyle ="width:" + this.zoom+"% "
    },   
  },
};
</script>
<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
