import { store } from "../store";

export default function authHeader() {
   // let user = JSON.parse(localStorage.getItem('user'));
    let token =   store.getters.accessToken
    
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }