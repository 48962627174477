<template>
  <div class="wrapper">
    <div class="section-shaped my-0 mt-8 skew-separator skew-mini">
      <div class="page-header page-header-mini mt-8 header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/sections/unsplashs.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5 ">
                <h2 >SIGN AND RUN</h2>
                <p class="text-lead text-red mb-8">
                  Gestión de eventos deportivos.
                </p>
                         <p class="text-lead ">
                  Acceso de Organizador.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="upper">
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-secondary shadow border-0">
            <div class="card-header bg-white pb-5">
                                       <p class="text-lead  text-center ">
                  Acceso de Organizador.
                </p>
              <div class="text-muted text-center mb-1">
                <small></small>
              </div>
          <!--    <div class="btn-wrapper text-center">
                <base-button
                  tag="a"
                  href="javascript:void(0);"
                  type="disabled"
                  class="btn-icon"
                >
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/facebook.svg"
                  /></span>
                  <span class="btn-inner--text">Facebook</span>
                </base-button>
                <base-button
                  tag="a"
                  href="javascript:void(0);"
                  type="disabled"
                  class="btn-icon"
                >
                  <span class="btn-inner--icon"
                    ><img src="img/icons/common/google.svg"
                  /></span>
                  <span class="btn-inner--text">Google</span>
                </base-button>
              </div> -->
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-2">
                
                <small>Acceder con credenciales</small>
              </div>
              <form role="form" >
                <div class="form-group mb-3">
                  <base-input
                    alternative 
                    placeholder="Usuario"
                    addonLeftIcon="ni ni-email-83"
                        v-model="user.username"
                        v-validate="'required|min:6|max:40'"

                  ></base-input>
                </div>
                <div class="form-group ">
                  <base-input
                    alternative type="password"
                    placeholder="Contraseña"
                    addonLeftIcon="ni ni-lock-circle-open"
                        v-model="user.password"
                    
                  ></base-input>
                </div>
                <div class="custom-control-alternative custom-checkbox pl-0">
                  <base-checkbox alternative>Recordarme </base-checkbox>
                </div>
                <div class="text-center">
                  
                  <base-button type="primary" class="my-2" @click="handleLogin" >Acceder</base-button>

                 
                </div>
                                <div class="text-center">
                  
                <!--  <base-button  type="disabled" class="my-2" @click="handleSignup" >Registrarse</base-button> -->

                 
                </div>
              </form>

                                
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>

  </div>
</template>


<script>
import User from '../models/user';
import jwt_decode from "jwt-decode";
import UserService from "../services/user.service";
import swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import Spinner from "../components/Spinner.vue";

export default {
  name: 'Login',
  bodyClass: "login-page",
  components: {

    Spinner,

  },

  data() {
    return {
      user: new User('', ''),
      isLoading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
   //   this.$router.push('/account');
    }
  },
  methods: {
     handleLogin() {
      let self=this;
      this.isLoading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          self.loading = false;
          //return;
        }

        if (self.user.username && self.user.password) {
          self.$store.dispatch('auth/login', this.user).then(
            (success) => {
              self.isLoading=false;
              var token = success.access;
              var decoded = jwt_decode(token);
              self.$store.commit("setUserId", decoded.user_id)
              self.$store.commit("setIsLogged", true);

              UserService.getOrganizador(decoded.user_id).then(
               res => {
                 var org1 = res.data.results[0]
                 self.$store.commit("setOrganizadorId", org1.id)
                 self.$store.commit("setOrganizador", org1)
                  self.$router.push('/listaeventos2');
                //return Promise.resolve(user);
              },
              error => {
                self.$store.commit("setIsLogged", false);
       
                return Promise.reject(error);
              });
            },
            error => {
              self.isLoading = false;
 swal.fire({
      title: 'Error!',
      text: 'Usuario o contraseña no válido.',
      buttonsStyling: false,
       confirmButtonClass: 'btn btn-warning',
      icon: 'warning'
    });
            }
          );
        }
      });
    }
  }
};
</script>



<style></style>
