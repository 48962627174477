<template>



<div class="wrapper">




        


 <div class="card col-lg-10 col-md-10 mx-auto text-center">
        <div class="border-0 card-header">
            <h3 class="mb-0">Light table</h3>
        </div>

       
        <div class="d-flex">
               <card class="col-lg-5 col-md-5">
              <!--Card Header-->
              <h3 slot="header" class="mb-0">Dropzone x</h3>
              <!--Card Body-->

              <dropzone-file-upload v-model="inputs.fileMultiple" multiple></dropzone-file-upload>
            </card>
            <div class="col-lg-2 col-md-2">
                
            </div>
               <card class="col-lg-5 col-md-5">
              <!--Card Header-->
              <h3 slot="header" class="mb-0">Pdf Dropzone</h3>
              <!--Card Body-->
            <h5 class="card-title">Card title</h5>

              <pdf-dropzone-file-upload v-model="inputs.fileSingle"  @changeFile="fileAdded($event)"></pdf-dropzone-file-upload>
            </card>            
        </div>


    </div>


</div>

</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import DropzoneFileUpload from '@/views/components/JavascriptComponents/FileUpload'
  import PdfDropzoneFileUpload from '@/views/components/JavascriptComponents/PdfFileUpload'

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    DropzoneFileUpload,
        PdfDropzoneFileUpload

  
    },
    data() {
      return {
          inputs: {
                 fileSingle: [],
                 fileMultiple: []
          },
        eventos: [
          {
            img: 'https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/bootstrap.jpg',
            title: 'Argon Design System',
            budget: '$2500 USD',
            status: 'pending',
            statusType: 'warning',
            completion: 60
          },
 
        ],
        currentPage: 1
      }
    },

  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI + "/getrentsforuserasowner/" + uuid;
      self.$http.get(url).then(
        function(success) {
          var transporterstemp = success.data.transporters;
          var id1 = transporterstemp[0].iduser;
          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del calendario de pagos ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    fileAdded(file){
        let vv = file
        this.inputs.fileSingle=file
            var reader = new FileReader()
    reader.readAsDataURL(this.inputs.fileSingle)
    reader.onload = ()=> {
       console.log(reader.result);
                     };

    }
    }
  }
</script>
