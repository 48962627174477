<template>
  <div >

    <div class="card col-lg-12 col-md-12 mx-auto text-center">
      <!--     <div class="border-0 card-header">
            <h3 class="mb-0">Participantes</h3>
        </div>
  -->
        <div class="col-md-5" style="padding:1px">
        <base-button @click="addParticipante()"
          >Nuevo Participante
        </base-button>
      </div>
      <el-table
        class="table-responsive table-light table-striped table align-items-center"
        header-row-class-name="thead-dark "
        :data="queriedData"
      >
        <el-table-column
          label="Nombre"
          prop="nombre"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Apellidos"
          prop="apellidos"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="DNI"
          prop="dni"
          align="center"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Email"
          prop="email"
          align="center"
          min-width="200px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Movil"
          align="center"
          prop="telefono"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <!--        <el-table-column
          label="Estado"
          align="center"
          min-width="170px"
          prop="state"
          sortable
        >
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <i :class="`bg-${row.statusType}`"></i>
              <span class="status">{{ row.state }}</span>
            </badge>
          </template>
        </el-table-column>
-->
        <el-table-column
          label="Estado"
          align="center"
          prop="completion"
          min-width="20px"
          sortable
        >
          <template v-slot="{ row }">
            <div class="d-flex align-items-center">
              <span class="completion mr-2">{{ row.strstate }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="20px" label="...">
          <template v-slot="{ row }">
            <el-dropdown trigger="click" class="dropdown">
              <span class="btn btn-sm btn-icon-only text-light">
                <i class="fas fa-ellipsis-v mt-2"></i>
              </span>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                slot="dropdown"
              >
                <a class="dropdown-item" @click="showFirma(row.id)"
                  >Ver firma</a
                >
                <a class="dropdown-item" href="#">...</a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="card-footer py-4 d-flex justify-content-end">
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </div>

    <div class="row ">

      <!--           <div class="col-md-5" style="padding:1px">
        <base-button @click="processXls()" type="primary"  v-show="showProcesar"
          >Procesar Excel</base-button
        >
        <span v-if='!showProcesar'>{{mensajeProcesar}}</span>
      </div> -->
      <div class="col-md-5" style="padding:1px">
        <base-button @click="addParticipante()"
          >Nuevo Participante
        </base-button>
      </div>
 <!--     <div class="row section" style="padding-top:200px">
        <lista-firmas></lista-firmas>
      </div>
-->
      <div
        class="d-flex justify-content-center mb-3 modal "
        style="padding-top:300px"
        v-if="isLoading"
      >
        <spinner
          label="Loading..."
          style="width: 3rem; height: 3rem;"
        ></spinner>
  <!--      <div class="col-md-6">
          <base-button @click="showProgress = true" type="warning"
            >Ver Progreso</base-button
          >
        </div> -->
      </div>

    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import Spinner from "../components/Spinner.vue";
//import ListaFirmasRefresh from "./ListaFirmasRefresh.vue";
//import ListaFirmas from "./ListaFirmas.vue";
import Modal from "@/components/Modal";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";

export default {
  mixins: [clientPaginationMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    Spinner,
  //  ListaFirmasRefresh,
 //   ListaFirmas,
   // Modal,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      participaciones: [],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      uuid: "",
      isLoading: false,
      showProgress: false,
      showProcesar: true,
      mensajeProcesar: "",
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;

    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let urlev =
        process.env.VUE_APP_BASE_URI +
        "api/v1/evento/" +
        this.$store.getters.eventouuid;
      self.$http.get(urlev).then(
        function(success) {
          self.evento = success.data;
          if (self.evento.xlsprocesado) {
            self.showProcesar = false;
            self.mensajeProcesar = "No hay Excel pendiente de procesarr";
          }
          //     self.oldevento  = JSON.parse(JSON.stringify(success.data));

          //    self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del evento  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );

      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/listparticipacion?evento_id=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          var participa = success.data.results;
          for (var i = 0; i < participa.length; i++) {
            var linea = {};
            linea.id = participa[i].id;
            linea.email = participa[i].customer.user.email;
            linea.dni = participa[i].customer.dni;
            linea.telefono = participa[i].customer.telefono;
            linea.seguro = participa[i].customer.seguro;
            linea.licencia = participa[i].customer.licencia;
            linea.nombre = participa[i].customer.user.first_name;
            linea.apellidos = participa[i].customer.user.last_name;
            linea.state = participa[i].state;
            if (linea.state == 0) {
              linea.strstate = "PENDIENTE";
            }
            if (linea.state == 1) {
              linea.strstate = "FALTA FIRMA";
            }
            if (linea.state == 2) {
              linea.strstate = "FIRMA GENERADA";
            }

            self.participaciones.push(linea);
          }
          self.tableData=self.participaciones;

          self.isLoading = false;
        //  self.loadData();
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar los datos.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
    generaFirmas(file) {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/createfirmas?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          let url2 =
            process.env.VUE_APP_BASE_URI +
            "api/v1/participacion?evento_id=" +
            self.$store.getters.eventouuid;
          self.$http.get(url2).then(
            function(success) {
              self.participaciones = [];
              var participa = success.data.results;
              var linea = {};
              for (var i = 0; i < participa.length; i++) {
                linea.id = participa[i].id;
                linea.email = participa[i].customer.user.email;
                linea.dni = participa[i].customer.dni;
                linea.telefono = participa[i].customer.telefono;
                linea.seguro = participa[i].customer.seguro;
                linea.licencia = participa[i].customer.licencia;
                linea.nombre = participa[i].customer.user.first_name;
                linea.apellidos = participa[i].customer.user.last_name;
                linea.state = participa[i].state;
                self.participaciones.push(linea);
              }

              self.isLoading = false;
              swal.fire({
                title: "Correcto!",
                text:
                  "Se han generado  las firmas para los nuevos participantes.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "success",
              });
              //   self.getUserData(id1);
            },
            function(error) {
              self.isLoading = false;
              swal.fire({
                title: "Error!",
                text: "Error al generar  firmas.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "warning",
              });
            }
          );
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos de participaciones ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    processXls() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/processxls?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          let url2 =
            process.env.VUE_APP_BASE_URI +
            "api/v1/participacion?evento_id=" +
            self.$store.getters.eventouuid;
          self.$http.get(url2).then(
            function(success) {
              self.participaciones = [];
              var participa = success.data.results;
              var linea = {};
              for (var i = 0; i < participa.length; i++) {
                linea.id = participa[i].id;
                linea.email = participa[i].customer.user.email;
                linea.dni = participa[i].customer.dni;
                linea.telefono = participa[i].customer.telefono;
                linea.seguro = participa[i].customer.seguro;
                linea.licencia = participa[i].customer.licencia;
                linea.nombre = participa[i].customer.user.first_name;
                linea.apellidos = participa[i].customer.user.last_name;
                linea.state = participa[i].state;
                self.participaciones.push(linea);
              }

              self.isLoading = false;
            },
            function(error) {
              self.isLoading = false;
              swal.fire({
                title: "Error!",
                text: "Error al generar firmas.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "warning",
              });
            }
          );
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "Error al procesar fichero.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    addParticipante: function() {
      this.$router.push({
        path: "/crearparticipacion/" + this.$store.getters.eventouuid,
        query: { id: this.$store.getters.eventouuid },
      });
    },    
    showFirma: function(uuid) {
      self.isLoading = true;
      let url2 =
        process.env.VUE_APP_BASE_URI +
        "api/v1/getoperation?opuuid=" +
        self.$store.getters.eventouuid;
      self.$http.get(url2).then(
        function(success) {
          self.participaciones = [];
          var participa = success.data;
          var linea = {};

          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "Error al acceder a detalle de firma fichero.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
  },
};
</script>
