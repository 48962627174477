import Vue from "vue";
import Router from "vue-router";
import Header from "./views/sections/Headers/HeaderSignRun.vue";
import HeaderFirma from "./views/sections/Headers/HeaderFirma.vue";
import HeaderPublic from "./views/sections/Headers/HeaderSignRunPublic.vue";
import Footer from "./layout/AppFooter.vue";
import Starter from "./views/Starter.vue";
import Login from "./views/Login.vue";
import Account from "./views/Account.vue";
import PerfilOrganizador from "./views/PerfilOrganizador.vue";
import ListaEventos from "./views/ListaEventos.vue";
import ListaEventos2 from "./views/ListaEventos2.vue";
import ListaEventosLibres from "./views/ListaEventosLibres.vue";
import LandingPage from "./views/LandingPage.vue";
import CrearEvento from "./views/CrearEvento.vue";
import UpdateEvento from "./views/UpdateEvento.vue";
import EventoDetail from "./views/EventoDetail.vue";
import Components from "./views/Components.vue";
import ContactUs from "./views/ContactUs.vue";
import Sections from "./views/Sections.vue";
import Rodada from "./views/Rodada.vue";
import Pricing from "./views/Pricing.vue";
//import Pdfsigner from "./views/Pdfsigner.vue";
import Testfirma from "./views/Testfirma.vue";
import EventoDetailShow from "./views/EventoDetailShow.vue";
import CrearParticipacion from "./views/CrearParticipacion.vue";
import CrearParticipacionPublic from "./views/CrearParticipacionPublic.vue";
import CrearRunner from "./views/CrearRunner.vue";
import Feature6 from "./views/sections/Features/Feature6.vue";
import PaginatedTable from "./views/PaginatedTable.vue";
import FirmaDetail from "./views/FirmaDetail.vue";
import Feature2 from "./views/sections/Features/Feature2.vue";
import FeaturesInit from "./views/sections/FeaturesInit.vue";
import OtpInput from "./views/OtpInput.vue";
import PdfAlreadySigned from "./views/PdfAlreadySigned.vue";
import PdfsignerUpload from "./views/PdfsignerUpload.vue";
import Finalizado from "./views/Finalizado.vue";



Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "starter",
      components: {
        header: HeaderPublic,
        default: FeaturesInit,
        footer: Footer
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: HeaderPublic,
        default: Login,
        footer: Footer
      }
    },
    {
      path: "/otpinput",
      name: "otpinput",
      components: {
        header: HeaderFirma,
        default: OtpInput,
        footer: Footer
      }
    },    
    {
      path: "/account",
      name: "account",
      components: {
        header: Header,
        default: Account,
        footer: Footer
      }
    },
    {
      path: "/perfil",
      name: "perfil",
      components: {
        header: Header,
        default: PerfilOrganizador,
        footer: Footer
      }
    },    
    {
      path: "/crearparti/",
      name: "crearparti",
      components: {
        header: Header,
        default: CrearParticipacion,
        footer: Footer
      }
    },   
    {
      path: "/crearpartipublic/:id",
      name: "crearpartipublic",
      components: {
        header: HeaderPublic,
        default: CrearParticipacionPublic,
        footer: Footer
      }
    },        
    {
      path: "/listaeventos",
      name: "listaeventos",
      components: {
        header: Header,
        default: ListaEventos,
        footer: Footer
      }
    },  
    {
      path: "/listaeventos2",
      name: "listaeventos2",
      components: {
        header: Header,
        default: ListaEventos2,
        footer: Footer
      }
    },  
    {
      path: "/listaeventoslibres",
      name: "listaeventoslibres",
      components: {
        header: HeaderPublic,
        default: ListaEventosLibres,
        footer: Footer
      }
    },      
    {
      path: "/paginated",
      name: "paginated",
      components: {
        header: Header,
        default: PaginatedTable,
        footer: Footer
      }
    },      
    {
      path: "/landing-page",
      name: "landing-page",
      components: {
        header: HeaderPublic,
        default: LandingPage,
        footer: Footer
      }
    },
    {
      path: "/feature2",
      name: "feature2",
      components: {
        header: Header,
        default: Feature2,
        footer: Footer
      }
    },  
    {
      path: "/crearevento",
      name: "crearevento",
      components: {
        header: Header,
        default: CrearEvento,
        footer: Footer
      }
    },
    {
      path: "/addficheros/:id",
      components: {
        header: Header,
        default: UpdateEvento,
        footer: Footer
      },
      name: "AddFicheros"
    },
    {
      path: "/eventodetail/:id",
      components: {
        header: Header,
        default: EventoDetail,
        footer: Footer
      },
      name: "EventoDetail"
    },
    {
      path: "/crearparticipacion/:id",
      components: {
        header: Header,
        default: CrearParticipacion,
        footer: Footer
      },
      name: "CrearParticipacion"
    },
    {
      path: "/eventoshow/:id",
      components: {
        header: HeaderPublic,
        default: EventoDetailShow,
        footer: Footer
      },
      name: "eventoshow"
    },  
    {
      path: "/crearrunner/:id",
      components: {
        header: Header,
        default: CrearRunner,
        footer: Footer
      },
      name: "CrearRunner"
    },      
    {
      path: "/contact-us",
      name: "contact-us",
      components: {
        header: Header,
        default: ContactUs,
        footer: Footer
      },
    },   
    {
      path: "/contact-uspub",
      name: "contact-uspub",
      components: {
        header: HeaderPublic,
        default: ContactUs,
        footer: Footer
      },
    },        
    {
      path: "/init",
      name: "init",
      components: {
        header: HeaderPublic,
        default: Feature6,
        footer: Footer
      },
    },
    {
      path: "/featuresinit",
      name: "featuresinit",
      components: {
        header: HeaderPublic,
        default: FeaturesInit,
        footer: Footer
      },
    },    
    {
      path: "/sections",
      name: "sections",
      components: {
        header: HeaderPublic,
        default: Sections,
        footer: Footer
      },
      props: {
        header: { navbarType: "default" }
      }
    },
    {
      path: "/pricing",
      name: "pricing",
      components: {
        header: Header,
        default: Pricing,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/firmadetail/:id",
      name: "firmadetail",
      components: {
        header: Header,
        default: FirmaDetail,
        footer: Footer
      }
    },  
    {
      path: "/rodada/:id",
      components: {
        header: Header,
        default: Rodada,
        footer: Footer
      },
      name: "rodada"
    },     
    {
      path: "/pdfsigner",
      name: "pdfsigner",
      components: {
        header: HeaderFirma,
        default: Pricing,
        footer: Footer
      }
    }, 
    {
      path: "/pdfalreadysigned",
      name: "pdfalreadysigned",
      components: {
        header: HeaderFirma,
        default: PdfAlreadySigned,
        footer: Footer
      }
    },  
    {
      path: "/pdfsignerupload",
      name: "pdfsignerupload",
      components: {
        header: HeaderFirma,
        default: PdfsignerUpload,
        footer: Footer
      }
    },         
    {
      path: "/testfirma",
      name: "testfirma",
      components: {
        header: Header,
        default: Testfirma,
        footer: Footer
      }
    }, 
        
    {
      path: "/finalizado",
      name: "finalizado",
      components: {
        header: HeaderFirma,
        default: Finalizado,
        footer: Footer
      }
    },  
  ]
});
