<template>
  <footer class="footer">
    <div class="container">
      <div class="row row-grid align-items-center mb-5">
   
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <a href="javascript:void();" target="_blank">Sign And Run</a>
          </div>
        </div>
        <div class="col-md-12">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a href="javascript:void()" class="nav-link" target="_blank"
                >Sign And Run</a
              >
            </li>
            <li class="nav-item">
              <a href="javascript:void()" class="nav-link" target="_blank"
                >About Us</a
              >
            </li>
      
          </ul>
        </div>
      </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
