
import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
//import mutations from "./mutations";
//import getters from "./getters";

import VuexPersist from "vuex-persist";
import { auth } from './auth.module';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'signandrun',
  storage: localStorage
})

export const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    isLogin : true,
    isLoading : false,
    sessionData : Object,
    token : "",
    validuser: "",
    validpassword:"",
    brandsbytype: [],
    attributesbytype: [],
    useralias: "",
    userid: 0,
    organizadorid: 0,
    organizadoruuid: "",
    organizador: Object ,
    eventouuid:"",
    opuuid:"",
    sessionToken: "",
    graphoimg:"",
    documentsUpload:[],
    documentsCompleted: false,
    isLogged:false



  },
  getters: {
    username (state) {
      return state.sessionData.username;
    },
    lastname (state) {
      return state.sessionData.lastname;
    },
    userId (state) {
      return state.sessionData.userid;
    },
    email (state) {
      return state.sessionData.email;
    },
    uuid (state) {
      return state.sessionData.uuid;
    },
    status (state) {
      return state.sessionData.status;
    },
    role (state) {
      return state.sessionData.role;
    },
    validuser (state) {
      return state.validuser;
    },
    validpassword (state) {
      return state.validpassword;
    },
    brandsbytype (state) {
      return state.brandsbytype;
    },
    attributesbytype (state) {
      return state.attributesbytype;
    },
    useralias (state) {
      return state.useralias;
    },
    organizador (state) {
      return state.organizador;
    },
    eventouuid (state) {
      return state.eventouuid;
    },
    opuuid (state) {
      return state.opuuid;
    },
    sessionToken (state) {
      return state.sessionToken;
    },
    graphoimg (state) {
      return state.graphoimg;
    },
    documentsUpload (state) {
      return state.documentsUpload;
    }, 
    documentsCompleted (state) {
      return state.documentsCompleted;
    },
    isLogged (state) {
      return state.isLogged;
    },                 
  },
  mutations:{
    reset (state) {
        this.state = state
      // acquire initial state
     
    },
    isLogin (state, value) {
      state.isLogin = value
    },
    isLoading (state, value) {
      state.isLoading = value
    },
    setToken (state, token) {
      state.token = token;
      let base64url = token.split('.')[1]
      let base64 = base64url.replace('-', '+').replace('_', '/')
      let claims = JSON.parse(window.atob(base64))
      let sessionData = claims.sub;
      state.sessionData = sessionData;
    },
    setValiduser (state, value) {
      state.validuser = value
    },
    setValidpassword (state, value) {
      state.validpassword = value
    },
    setUseralias (state, value) {
      state.useralias = value
    },
    setUserId(state, value){
      state.userid=value
    },
    setOrganizadorId(state, value){
      state.organizadorid=value
    },
    setOrganizadorUuid(state, value){
      state.organizadoruuid=value
    },
    setOrganizador(state, value){
      state.organizador=value
    },
    setEventouuid(state, value){
      state.eventouuid=value
    },
    setOpuuid(state, value){
      state.opuuid=value
    },
    setGraphoimg(state, value){
      state.graphoimg=value
    },
    setDocumentsUpload(state, value){
      state.documentsUpload=value
    },
    setDocumentsCompleted(state, value){
      state.documentsCompleted=value
    },
    setIsLogged(state, value){
      state.isLogged=value
    }    
  },
  actions,
  modules: {

    auth
  }
});


export const store2 = store


