<template>
  <div class="wrapper">
    <div class="card col-lg-12 col-md-12 mx-auto text-center">
      <div class="border-0 card-header">
        <h3 class="mb-0">Firmas Generadas</h3>
      </div>
      <div
        class="d-flex justify-content-center mb-3 modal "
        style="padding-top:300px"
        v-if="isLoading"
      >
        <spinner
          label="Loading..."
          style="width: 3rem; height: 3rem;"
        ></spinner>
      </div>
      <el-table
        class="table-responsive table-light table-striped table align-items-center "
        header-row-class-name="thead-dark "
        :data="queriedData"
      >
        <el-table-column
          label="Nombre"
          prop="nombre"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Apellidos"
          prop="apellidos"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column label="DNI" prop="dni" min-width="140px" sortable>
        </el-table-column>
        <el-table-column label="Email" prop="email" min-width="200px" sortable>
        </el-table-column>
        <el-table-column
          label="Movil"
          align="center"
          prop="telefono"
          min-width="140px"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="Estado"
          align="center"
          min-width="170px"
          prop="state"
          sortable
        >
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <i :class="`bg-${row.statusType}`"></i>
              <span class="status">{{ row.strstate }}</span>
            </badge>
          </template>
        </el-table-column>

        <el-table-column min-width="20px">
          <template v-slot="{ row }">
            <el-dropdown trigger="click" class="dropdown">
              <span class="btn btn-sm btn-icon-only text-bold">
                <!--  <i class="fas fa-ellipsis-v mt-2"></i>  -->
                <i class="ni ni-atom mt-2"></i>
              </span>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                slot="dropdown"
              >
                <a class="dropdown-item" href="#" @click="showFirma(row.opuuid)"
                  >Ver Firma</a
                >
                <a class="dropdown-item" href="#">------</a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
                <div class="card-footer py-4 d-flex justify-content-end">
          <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>      
    </div>
    </div>

    <div class="row mt-12">
      <div class="col-md-12">
        <base-button @click="exportarDocumentos()" type="primary"
          >Descargar Documentos Firmados</base-button
        >

        <base-button @click="updateStatus()" type="primary"
          >Actualizar estado</base-button
        >
      </div>


      <modal :show.sync="modal0" modal-classes="modal-dialog-centered modal-lg">
        <h5 slot="header" class="modal-title" id="modal-title-default">
          Detalles de firma
        </h5>
       <firma-detail ref="childref" > </firma-detail> 

        <template slot="footer">
          <base-button type="secondary" class="ml-auto" @click="modal0 = false"
            >Cerrar
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import Spinner from "../components/Spinner.vue";
import FirmaDetail from "./FirmaDetail.vue";
import Modal from "@/components/Modal";
import { EventBus } from "../eventBus";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin';

export default {
      mixins: [clientPaginationMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    Spinner,
    FirmaDetail,
    Modal,
  },
  data() {
    return {
      firmas: [],
             tableData: [],

      currentPage: 1,
      uuid: "",
      isLoading: false,
      timer: "",

      modal0: false,
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;

    this.loadData();
  },

  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/getfirmadocs?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          var participa = success.data;
          self.firmas = [];
          for (var i = 0; i < participa.length; i++) {
            var linea = {};
            linea.id = participa[i].id;
            linea.email = participa[i].email;
            linea.dni = participa[i].dni;
            linea.telefono = participa[i].telefono;
            linea.nombre = participa[i].nombre;
            linea.apellidos = participa[i].apellidos;
            linea.state = participa[i].estado;
            linea.opuuid = participa[i].opuuid;
            if (linea.state == 0) {
              linea.strstate = "PENDIENTE";
            }
            if (linea.state == 10) {
              linea.strstate = "ACCESO";
            }
            if (linea.state == 30) {
              linea.strstate = "FIRMADA";
            }
            if (linea.state > 89) {
              linea.strstate = "CANCELADA";
            }
            self.firmas.push(linea);
          }
          self.tableData=self.firmas;

          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos de firmas ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    updateStatus() {
      const self = this;
      self.isLoading = true;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/updatestatusfirmas/?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          self.loadData();
          self.isLoading = false;
          let opstat = success.data;
          swal.fire({
            title: "Hecho",
            text: "Estado de firma actualizado.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            icon: "success",
          });
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar el documento.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },

    exportarDocumentos() {
      const self = this;
      self.isLoading = true;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/getzippeddocs/?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url, { responseType:'arraybuffer'}).then(
        function(success) {
          self.forceFileDownload(success, "documentos.zip");

          self.loadData();
          self.isLoading = false;
          //let opstat = success.data;
          swal.fire({
            title: "Hecho",
            text: "Documentos exportados.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success",
            icon: "success",
          });
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar el documento.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
 str2bytes (str) {
   var bytes = new Uint8Array(str.length);
   for (var i=0; i<str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
},
   forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadPdf(opuuid, docuuid) {
      const self = this;
 
      UserService.getPdfNob64(opuuid, docuuid).then(
        (data) => {
          self.forceFileDownload(data, "documento.pdf");
        },
        (error) => {
 swal.fire({
      title: 'Error!',
      text: 'No se ha podido cargar el documento.',
      buttonsStyling: false,
       confirmButtonClass: 'btn btn-warning',
      icon: 'warning'
    });
        }
      );
    },


    fileAdded(file) {
      let vv = file;
      if (file == null) {
        this.inputs.fileSingle = null;
        this.readerpdf = "";
        return;
      }
      const self = this;
      this.inputs.fileSingle = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.inputs.fileSingle);
      reader.onload = () => {
        console.log(reader.result);
        self.readerpdf = reader.result;
      };
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    gotoDetail: function(uuid) {
      this.$router.push({
        path: "/eventodetail/" + uuid,
        query: { id: uuid },
      });
    },
    showFirma: function(uuid) {
      const self = this;
      self.modal0 = true;
      this.$refs.childref.setUuid(uuid);

      EventBus.$emit("setFirmaUuid", uuid);
      /*
       let url2 =
            process.env.VUE_APP_BASE_URI +
            "api/v1/getoperation?opuuid=" +
            uuid;
          self.$http.get(url2).then(
            function(success) {
              self.participaciones=[]
              var participa = success.data;
              self.modal0 = true;
              var linea = {};

              self.isLoading = false;
        },
            function(error) {
              self.isLoading = false;
              self.snacktext = "Error al cargar datos de participaciones ";
              self.snackcolor = "error";
              self.snackresult = true;
            }
          );
          */
    },
  },
};
</script>
