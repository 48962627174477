<template>
  <div class="wrapper">
    <Feature2></Feature2>
    <!-- First Feature Section -->
    <Feature></Feature>
    <!-- Second Feature Section -->

    <!-- Blog Section -->
  <!--  <Blog></Blog> -->
    <br />
    <br />
    <br />
    <!-- Pricing Section -->
   <!-- <Pricing></Pricing> -->
  </div>
</template>
<script>
import Feature from "./sections/Features/Feature6.vue";
import Feature2 from "./sections/Features/Feature1.vue";
//import Blog from "./sections/Blogs/Blog2.vue";
//import Pricing from "./sections/Pricing/Pricing5.vue";
//import FileUploadDropZone2 from "./components/JavascriptComponents/FileUploadDropzone2"
import Inputs from "./components/JavascriptComponents/FileUploadDropzone2"


export default {
  components: {
    Feature,
    Feature2,

  }
};
</script>
<style></style>
