<template>
  <div class="wrapper">
    <div >
      <div class="r">

        <div class="container">
          <div class="header-body text-center mb-7 mt-5">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5 mt-5">
                <h1 class="text-white">Mi Perfil</h1>
                <p class="text-lead text-white"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary">
      <div class="container bg-white card mb-0">
        <div class="row">
          <div class="col-md-3">
            <div class="section">
              <!-- User Information -->
              <section class="text-center">
                <img :src="currentOrg.imagen1" class="avatar" />
                <h3 class="title mt-4">{{ currentOrg.nombre }}</h3>
              </section>
              <!-- User Information -->
              <!-- Profile Sidebar -->
              <section>
                                            <vue-core-video-player :src="currentOrg.video1"></vue-core-video-player>

                <br />
                <tabs
                  v-model="accountTab"
                  vertical
                  tabNavWrapperClasses="col-md-12 text-center"
                  no-content-space
                >
                  <tab-pane label="Mis Datos"></tab-pane>
                  <tab-pane label="Imagenes"></tab-pane>
            <!--      <tab-pane label="Finanzas"></tab-pane>
                  <tab-pane label="Notificaciones"></tab-pane>  -->
                </tabs>
              </section>
              <!-- End Profile Sidebar -->
              <!-- Profile Completion -->
              <br /><br /><br />

              <!-- End Profile Completion -->
            </div>
          </div>
          <div class="col-md-8 ml-auto">
            <div class="section">
              <div class="tab-content">
                <div v-if="accountTab === 'Mis Datos'" class="tab-pane active">
                  <div>
                    <header>
                      <h3 class="text-uppercase">Mis Datos</h3>
                    </header>
                    <hr class="line-primary" />
                    <br />
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#firstName"
                          >Nombre de usuario</label
                        >
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="firstName"
                          name="firstName"
                          type="text"
                          :value="currentOrg.user.username"
                          disabled
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#email">Email</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="email"
                          name="email"
                          type="text"
                          :value="currentOrg.user.email"
                          disabled
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#firstName">Nombre</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="nombre"
                          name="Nombre"
                          type="text"
                          v-model="currentOrg.nombre"
                        ></base-input>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">I’m</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="form-group">
                          <choices-single :options="gender" v-model="selected">
                            <option disabled value="0">Select one</option>
                          </choices-single>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#location">Dirección</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="direccion"
                          name="location"
                          type="text"
                          v-model="currentOrg.direccion"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone">Teléfono</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input
                          id="telefono"
                          name="phone"
                          type="tel"
                          v-model="currentOrg.telefono"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone">Web</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input
                          id="website"
                          name="phone"
                          type="tel"
                          v-model="currentOrg.website"
                        ></base-input>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-md-6">
                        <base-button
                          nativeType="submit"
                          type="primary"
                          @click="updateOrganizador"
                          >Guardar</base-button
                        >
                        <base-button nativeType="reset" outline type="primary"
                          >Cancelar</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="accountTab === 'Imagenes'" class="tab-pane active">
                  <header>
                    <h3 class="text-uppercase">Imágenes</h3>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <table class="table align-items-center">
                    <thead>
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Imágenes</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          <span class="d-block">Imagen 1 / Logo</span>
                        </th>
                        <th scope="row">
                          <img
                            alt="Image" width="200px" 
                            :src="currentOrg.imagen1"
                            class=""
                          />
                        </th>

                        <td class="text-center">
                          <label class="file-select">
                            <div class="select-button small" style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir imagen</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = 'imagen1'"
                              @change="handleFileChange"
                            />
                          </label>
                        </td>
                      </tr>

                      <tr>
                        <th>
                          <span class="d-block">Imagen 2</span>
                        </th>
                        <th scope="row">
                          <img
                            alt="Image" width="200px" 
                            :src="currentOrg.imagen2"
                            class=""
                          />
                        </th>

                        <td class="text-center">
                          <label class="file-select">
                            <div class="select-button small"  style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir imagen</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = 'imagen2'"
                              @change="handleFileChange"
                            />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="d-block">Imagen 3</span>
                        </th>
                        <th scope="row">
                          <img
                            alt="Image" width="200px" 
                            :src="currentOrg.imagen3"
                            class=""
                          />
                        </th>

                        <td class="text-center">
                          <label class="file-select">
                            <div class="select-button small "  style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir imagen</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = 'imagen3'"
                              @change="handleFileChange"
                            />
                          </label>
                        </td>
                      </tr>

                       <tr>
                        <th>
                          <span class="d-block">Video 1</span>
                        </th>
                        <th scope="row">
                          <div>
    <v-playback :url="currentOrg.video1" ></v-playback>
                          </div>
                        </th>

                        <td class="text-center">
                          <label class="file-select">
                            <div class="select-button small "  style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir video</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = 'video1'"
                              @change="handleFileChange"
                            />
                          </label>
                        </td>
                      </tr>                    
                    </tbody>
                  </table>

                </div>

                <div v-if="accountTab === 'Finanzas'" class="tab-pane active">
                  <header>
                    <h2 class="text-uppercase">Billing method</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <table class="table align-items-center">
                    <thead>
                      <tr>
                        <th scope="col">Card Type</th>
                        <th scope="col">Card Number</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <img alt="Image" src="img/visas.png" class="avatar" />
                        </th>
                        <td>
                          <span class="d-block">•••• •••• •••• 8372</span>
                          <small class="text-muted">Exp: 06/22</small>
                        </td>
                        <td class="text-center">
                          <base-radio id="Radios1" checked></base-radio>
                        </td>
                        <td>
                          <base-button type="danger" simple size="sm">
                            Remove card
                          </base-button>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                  <base-button type="primary" size="sm">
                    Add card
                  </base-button>
                </div>

                <div v-if="accountTab === 'Security'" class="tab-pane active">
                  <header>
                    <h2 class="text-uppercase mb-0">Security Questions</h2>
                  </header>
                  <hr class="line-primary" />
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Security Question</label>
                        <div class="form-group">
                          <choices-single
                            :options="questions"
                            v-model="selected"
                          >
                            <option disabled value="0">Select one</option>
                          </choices-single>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label>Your Answer</label>
                        <base-input
                          placeholder="Enter your answer"
                          type="text"
                        ></base-input>
                      </div>
                    </div>
                    <br />
                    <br />
                    <header>
                      <h2 class="text-uppercase">Security Settings</h2>
                    </header>
                    <hr class="line-primary" />
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span>Notify me via email when logging in</span>
                      <base-switch
                        v-model="switches.off"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span>Send SMS confirmation for all online payments</span>
                      <base-switch
                        v-model="switches.on"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span>Check which devices accessed your account</span>
                      <base-switch
                        v-model="switches.off"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span
                        >Find My Device, make sure your device can be found if
                        it gets lost</span
                      >
                      <base-switch
                        v-model="switches.on"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span
                        >Lock your device with a PIN, pattern, or password</span
                      >
                      <base-switch
                        v-model="switches.on"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div
                      class="form-group d-flex align-items-center justify-content-between"
                    >
                      <span
                        >Manage what apps have access to app-usage data on your
                        device</span
                      >
                      <base-switch
                        v-model="switches.off"
                        type="primary"
                      ></base-switch>
                    </div>
                    <div class="row mt-5 justify-content-end">
                      <div class="col-md-4 actions">
                        <base-button
                          type="primary"
                          outline
                          size="sm"
                          nativeType="reset"
                          >Cancel</base-button
                        >
                        <base-button type="primary" size="sm"
                          >Save Changes</base-button
                        >
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  v-if="accountTab === 'Notifications'"
                  class="tab-pane active"
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <base-alert type="primary">
                          We will never distribute your email address to third
                          parties. Read about email communication in our privacy
                          policy.
                        </base-alert>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-12">
                        <form>
                          <h5 class="mb-4">Notification Preferences</h5>
                          <base-checkbox class="mb-3" checked
                            >Someone mentions me</base-checkbox
                          >
                          <base-checkbox class="mb-3" checked
                            >Someone follows me</base-checkbox
                          >
                          <base-checkbox class="mb-3"
                            >Someone shares my activty</base-checkbox
                          >
                          <base-checkbox class="mb-3"
                            >Someone messages me</base-checkbox
                          >
                          <base-checkbox class="mb-3"
                            >Someone adds me to a project</base-checkbox
                          >
                          <base-checkbox class="mb-3"
                            >Sales and promotions</base-checkbox
                          >
                          <base-button
                            nativeType="submit"
                            type="primary"
                            size="sm"
                            class="mt-4 mb-5"
                            >Update preferences</base-button
                          >
                        </form>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-12">
                        <form>
                          <h5>Notification Frequency</h5>
                          <base-radio
                            name="radio0"
                            class="mb-3"
                            v-model="radio.radio1"
                            >Daily</base-radio
                          >
                          <base-radio
                            name="radio1"
                            class="mb-3"
                            v-model="radio.radio1"
                            >Weekly</base-radio
                          >
                          <base-radio
                            name="radio2"
                            class="mb-3"
                            v-model="radio.radio1"
                            >Monthly</base-radio
                          >
                          <base-radio
                            name="radio3"
                            class="mb-3"
                            v-model="radio.radio1"
                            >Never</base-radio
                          >
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import ImageUpload from "@/components/ImageUpload";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import ChoicesSingle from "@/components/SingleSelect";
import Tags from "@/components/Tags";
import BaseAlert from "@/components/BaseAlert";
import UserService from "../services/user.service";
import axios from "axios";
import authHeader from "../services/auth-header";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
//import VueCoreVideoPlayer from 'vue-core-video-player';


export default {
    name: 'app',

  bodyClass: "account-settings",
  components: {
  //  ImageUpload,
    Tabs,
    TabPane,
    ChoicesSingle,
  //  Tags,
    BaseAlert,
  },
  props: {
    value: File,
  },

  data() {
    return {
      accountTab: "Mis Datos",
      currentOrg: {},
      selected: 2,
      tipoimagen: "",
      oldOrganizador: {},
      gender: [
        { id: 1, text: "Male" },
        { id: 2, text: "Female" },
      ],
      months: [],
      days: [],
      years: [],
      languages: [],
      questions: [],
      switches: {
        off: false,
        on: true,
      },
      radio: {
        radio1: "radio1",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },
    loadData() {
      const self = this;
      var a = this.$store.getters.organizador;
      UserService.getOrganizador(a.user.id).then(
        (res) => {
          var org1 = res.data.results[0];
          console.log(org1);
          self.currentOrg = org1;
          self.oldOrganizador = self.$store.getters.organizador;

          //return Promise.resolve(user);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      this.currentOrg = a;
      this.oldOrganizador = a;
    },
    updateOrganizador() {
      var changes = {};
      const API_URL = process.env.VUE_APP_BASE_URI + "api/v1/";

      const keys1 = Object.keys(this.currentOrg);
      const keys2 = Object.keys(this.oldOrganizador);
      for (let key of keys1) {
        if (this.currentOrg[key] !== this.oldOrganizador[key]) {
          if (key == "user") continue;
          if (key.indexOf("image") > -1) continue;
          if (key.indexOf("video") > -1) continue;
          changes[key] = this.currentOrg[key];
          //  if( key.includes('fecha') || key.includes('inscripcion'))
          //    changes[key] = this.evento[key]+"T12:00"
        }
      }
      const self = this;
      console.log(changes);
      axios
        .patch(
          API_URL +
            "organizador/" +
            this.currentOrg["id"] +
            "/?user=" +
            this.currentOrg["id"],
          changes
        )
        .then(
          (response) => {
            if (response.data) {
              console.log(response);
              swal.fire({
                title: `Perfil modificado`,
                text: `Datos de Organizador modificados...`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-primary",
              });

              UserService.getOrganizador(self.currentOrg.user.id).then(
                (res) => {
                  var org1 = res.data.results[0];
                  self.$store.commit("setOrganizador", org1);
                  //return Promise.resolve(user);
                },
                (error) => {
                  return Promise.reject(error);
                }
              );
              self.currentOrg = response.data;
              self.oldOrganizador = JSON.parse(JSON.stringify(response.data));
            }
          },
          (error) => {
            self.modalerrorr = true;

            return Promise.reject(error);
          }
        );
    },

    updateImagen(imagen, tipo) {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(imagen);
      reader.onload = () => {
        self.readerimg = reader.result;
        var datos = self.readerimg;
        if (self.readerimg.split(",")[0].indexOf("base64") >= 0) {
          datos = self.readerimg.split(",")[1];
        }
        var data = {
          evento: this.uuid,
          tipofichero: tipo,
          file: datos,
        };
        UserService.updateimageninorganizador(data).then(
          (user) => {
            swal.fire({
              title: "Fichero añadido",
              text: "Se ha añadido el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "success",
            });
            self.isLoading = false;
            this.loadData();
          },
          (error) => {
            swal.fire({
              title: "Fichero no añadido",
              text: "Error al añadir el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-error",
              icon: "error",
            });
            self.isLoading = false;
            self.readerxls = "";
          }
        );
      };
    },
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
      this.updateImagen(e.target.files[0], this.tipoimagen);
    },
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}

.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2ea169;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
</style>
