import axios from 'axios';
import authHeader from './auth-header';
import {store} from './../store'

const API_URL = process.env.VUE_APP_BASE_URI + 'api/v1/';
const API_URL_LT = process.env.VUE_APP_BASE_URI + 'api/laptracker/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user/', { headers: authHeader() });
  }

  getOrganizadorBoard() {
    return axios.get(API_URL + '', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getTiposEvento() {
    return axios.get(API_URL + 'tipoevento', { headers: authHeader() });
  }

  getPlantillas() {
    return axios.get(API_URL + 'doctemplate', { headers: authHeader() });
  }


  getEvento(eventoid) {
    return axios.get(API_URL + 'evento/?evento='+ eventoid, { headers: authHeader() });
  }  

  getXls(eventoid) {
    return axios.get(API_URL + 'downloadxls?evento='+ eventoid, { headers: authHeader() , responseType: "blob"});
  }

  getPdf(opuuid, docuuid ) {
    return axios.get(API_URL + "getdocumentb64/?opuuid=" + opuuid +'&docuuid='+docuuid , { headers: authHeader() , responseType: "blob"});
  }

  getPdfNob64(opuuid,docuuid ) {
    return axios.get(API_URL + "getdocumentpdf/?opuuid=" + opuuid +'&docuuid='+docuuid , { headers: authHeader() , responseType: "blob"});
  }

  getPdfNob64Url(opuuid,url ) {
    return axios.get(url, { headers: authHeader() , responseType: "blob"});
  }

  getOrganizador(userid) {
    return axios.get(API_URL + 'organizador?user='+ userid, { headers: authHeader() });
  }


  getRodadaFromEvento(eventoid) {
    return axios.get(API_URL_LT + 'laptrackerevento/?eventodef='+ eventoid, { headers: authHeader() });
  }  


  createevento(evento) {
    let ss = store.state.token
  return axios
    .post(API_URL + 'createevento/', evento, { headers: authHeader() })
    .then(response => {
      if (response.data) {

    //    _this.$store.commit("isLogin", false);
  
        //this.$router.push({path: '/myMachines'})
        //his.posts = data;



      }

      return response.data;
    });
}


createparticipacion(parti) {
  let ss = store.state.token
return axios
  .post(API_URL + 'createparticipacion/', parti, { headers: authHeader() })
  .then(response => {
    if (response.data) {


    }

    return response.data;
  });
}



createrunner(parti) {
  let ss = store.state.token
return axios
  .post(API_URL_LT + 'createrunner/', parti, { headers: authHeader() })
  .then(response => {
    if (response.data) {


    }

    return response.data;
  });
}

updatefileinevento(filedata) {
  let ss = store.state.token
  return axios
  .post(API_URL + 'updatefilesinevento/', filedata, { headers: authHeader() })
  .then(response => {
    if (response.data) {

    }

    return response.data;
  });
  return null;
}


updateimageninevento(filedata) {
  let ss = store.state.token
  return axios
  .post(API_URL + 'updateimageninevento/', filedata, { headers: authHeader() })
  .then(response => {
    if (response.data) {

    }

    return response.data;
  });
  return null;
}

updateimageninorganizador(filedata) {
  let ss = store.state.token
  return axios
  .post(API_URL + 'updateimageninorganizador/', filedata, { headers: authHeader() })
  .then(response => {
    if (response.data) {

    }

    return response.data;
  });
  return null;
}

updateexcelinrodada(filedata) {
  let ss = store.state.token
  return axios
  .post(API_URL_LT + 'updatefilesinrodada/', filedata, { headers: authHeader() })
  .then(response => {
    if (response.data) {

    }

    return response.data;
  });
  return null;
}


createtemplate(filedata) {
  let ss = store.state.token
  return axios
  .post(API_URL_LT + 'createdocumentotemplate/', filedata, { headers: authHeader() })
  .then(response => {
    if (response.data) {

    }

    return response.data;
  });
  return null;
}



}

export default new UserService();