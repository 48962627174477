<template>
  <div class="fileinput text-center">
    <div
      class="fileinput-new thumbnail"
    >
      <img src="img/image_placeholder.jpg" alt="preview" />
    </div>
    <div>
      <span class="btn btn-file" :class="btnClasses">
        <span class="fileinput-new">{{
          fileExists ? changeText : selectText
        }}</span>
        <input type="hidden" value="" name="" />
        <input
          accept=".xls,.xlsx"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
        />
      </span>
      <base-button v-if="fileExists" @click="removeFile" round type="danger">
        <i class="fa fa-times"></i> {{ removeText }}
      </base-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "xls-upload",
  props: {
    type: {
      type: String,
      default: "",
      description: 'Document upload type (""|xls|xlsx)'
    },
    btnClasses: {
      type: String,
      default: "",
      description: "Add xls button classes"
    },
    src: {
      type: String,
      default: "",
      description: "Initial image to display"
    },
    selectText: {
      type: String,
      default: "Elige  documento"
    },
    changeText: {
      type: String,
      default: "Cambiar"
    },
    removeText: {
      type: String,
      default: "Eliminar"
    }
  },
  data() {
    let imgPlaceholder = "img/image_placeholder.jpg";
    return {
      placeholder:  imgPlaceholder,
      imagePreview: null
    };
  },
  computed: {
    fileExists() {
      return this.imagePreview !== null;
    },
    xlsdocument() {
      return this.imagePreview || this.src || this.placeholder;
    }
  },
  methods: {
    handlePreview(event) {
      let file = event.target.files[0];
      if(file.name.type=='xlsx'){
     //   alert('XLS')
      }
      else{
     //   this.imagePreview = URL.createObjectURL(file);

      }
      this.$emit("change", file);
    },
    removeFile() {
      this.imagePreview = null;
      this.$emit("change", null);
    }
  }
};
</script>
<style></style>
