<template>
  <div id="app">
    
    		<ModalIdle  v-if="isIdle"/>

    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import ModalIdle from "@/views/ModalIdle";

export default {
  components: {
    FadeTransition,
    ModalIdle
      },
	computed: {
		isIdle() {


      	return this.$store.state.idleVue.isIdle;
  	},     
  }, 
  updated: function () {
    this.$nextTick(function () {
      document.body.classList.remove('modal-open');
    });
  }
};
</script>
