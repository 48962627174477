<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="border-4 card-header" style="padding-top:20px">
        <h3 class="mb-0">Eventos</h3>
      </div>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="border-4 card-headercol-lg-10" style="padding-top:20px">
        <h3 class="mb-0">Eventos</h3>
      </div>
      <div class="row col-12">
        <div class="col-lg-2">
        <base-button
          nativeType="reset"
          outline
          @click="gotoCrearEventos()"
          type="primary"
          >Crear Evento</base-button
        >
        </div>
        <div class="col-lg-4 align-items-end">
          <base-input v-model="searchQuery"
                      prepend-icon="fas fa-search"
                      placeholder="Buscar...">
          </base-input>
        </div>

       
      </div>
      <div>


      </div>
      <el-table
        class="table-responsive table-flush table align-items-center my-table-scroll"
        header-row-class-name="thead-light"
        :data="queriedData"
                row-key="id"
              
                @selection-change="selectionChange">
      >
        <el-table-column label="Tipo" prop="nombre" min-width="20px" sortable>
          <template v-slot="{ row }">
            <div
              class="media align-items-center text-right"
              style="padding-left:1rem"
            >
              <a href="#" class=" rounded-pill mr-1 align-items-center">
                <i :class="`ni ni-${row.icono}`"></i>
              </a>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Evento" min-width="310px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.nombre
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Fecha" min-width="100px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  moment(row.fecha).format("DD/MM/YYYY HH:mm")
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Fin Inscripción" min-width="100px" prop="name"  sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center  ">
              <div class="media-body ">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  moment(row.fininscripcion).format("DD/MM/YYYY HH:mm")
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>


  <!--      <el-table-column label="Estado" prop="budget" min-width="60px" sortable>
          <template v-slot="{ row }">
            <div
              class="media align-items-center"
              style="font-size: 1.3rem;  padding-top:5px; padding-left:50px;color: green "
            >
              <i :class="`ni ni-${row.icon}`"></i>
            </div>
          </template>
        </el-table-column>  -->

        <el-table-column
          label="Estado"
          min-width="170px"
          prop="status"
          sortable
        >
          <template v-slot="{ row }">
            <div class="badge-dot mr-2" type="" style="font-size: 1.0rem;  padding-top:1px; padding-left:50px;" :color="colores">
              <i :class="`ni ni-${row.icon}`"></i>
              <span class="status">{{ row.estado }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Inscripciones"
          prop="completion"
          min-width="130px"
          sortable
        >
          <template v-slot="{ row }">
            <div class="d-flex align-items-center">
                               <span class="completion mr-2" style="padding-top:0.5rem">{{ row.numinscritos }}</span>
               <base-progress  :value="row.progress" class="align-items-lg-start" style="padding-top:0.5rem"/>
              <span class="completion mr-2" style="padding-top:0.5rem"> {{ row.maxparticipantes }}</span>

            </div>
          </template>
        </el-table-column>

        <el-table-column min-width="180px">
          
          <template v-slot="{ row }">
                          <span
                class="btn btn-sm btn-icon-only text-bold"
                style="font-size:1.2rem; color: blue; vertical-align:center"
                @click="gotoDetail(row.id)"
              >
                <i class="ni ni-zoom-split-in mt-2"></i>
              </span>
            <el-dropdown trigger="click" class="dropdown">
              <span
                class="btn btn-sm btn-icon-only text-bold"
                style="font-size:1.2rem; color: red; vertical-align:center"
              >
                <i class="ni ni-settings-gear-65 mt-2"></i>
              </span>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                slot="dropdown"
              >
                <a class="dropdown-item" href="#" @click="gotoDetail(row.id)"
                  >Detalles</a
                >
                <a class="dropdown-item" href="#">Cancelar evento</a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

          <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
    </div>

  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option
} from "element-ui";
import Spinner from "../components/Spinner.vue";
import clientPaginationMixin from './PaginatedTables/clientPaginationMixin';

export default {
    mixins: [clientPaginationMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
        [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      filter:'',
      eventos: [],     
       tableData: [],
      selectedRows: [],
      currentPage: 1,
      propsToSearch: ['nombre', 'fecha', 'estado'],
      colores: 'green'

    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/listeventos?username=" +
        this.$store.getters.organizador.id;
      self.$http.get(url).then(
        function(success) {
          var eventost = success.data.results;

          for (var i = 0; i < eventost.length; i++) {
            var linea = {};

            linea.id = eventost[i].id;
            linea.nombre = eventost[i].nombre;
            linea.estado = eventost[i].estado;
            linea.fecha = eventost[i].fecha;
            linea.fininscripcion = eventost[i].fininscripcion;
            linea.maxparticipantes = eventost[i].maxparticipantes;
            if( linea.maxparticipantes === 0){
              linea.maxparticipantes =100;
            }
            if (eventost[i].estado == 1) {
              linea.icon = "money-coins";
            } else if (eventost[i].estado == 10) {
              linea.icon = "spaceship";
            } else {
              linea.icon = "ni ni-chart-bar-32";
            }
            if (eventost[i].tipo !== null) linea.icono = eventost[i].tipo_icono;
            else linea.icono = "button-power";
            
            linea.numinscritos= eventost[i].total_partis;
            linea.progress =  linea.numinscritos / linea.maxparticipantes *100

         //   if(eventost[i].estadofirmas != null ){
         //     linea.numinscritos= eventost[i].total_partis;
         //   }
         //   else{
         //     linea.numinscritos = 0;
         //   }

            linea.progress = 0;
            if(linea.maxparticipantes != 0 ){
              linea.progress = linea.numinscritos/linea.maxparticipantes * 100
            }
            self.eventos.push(linea);
          }
          self.tableData=self.eventos;
          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    fileAdded(file) {
      let vv = file;
      this.inputs.fileSingle = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.inputs.fileSingle);
      reader.onload = () => {
        console.log(reader.result);
      };
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    gotoDetail: function(uuid) {
      this.$router.push({
        path: "/eventodetail/" + uuid,
        query: { id: uuid },
      });
    },
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },        
  },
};
</script>

<style scoped>

body {
  padding: 1rem;
}

table.my-table-scroll,
table.my-table-scroll>thead,
table.my-table-scroll>tbody,
table.my-table-scroll>tfoot,
table.my-table-scroll>tbody>tr,
table.my-table-scroll>thead>tr {
  width: 100%;
  display: block;
}

table.my-table-scroll>thead,
table.my-table-scroll>tbody,
table.my-table-scroll>tfoot {
  display: block;
  width: 100%;
  overflow-y: scroll;
}

table.my-table-scroll>thead,
table.my-table-scroll>tfoot {
  height: auto;
}

table.my-table-scroll>tbody {
  max-height: 135px;
}

table.my-table-scroll>thead>tr>th,
table.my-table-scroll>thead>tr>td,
table.my-table-scroll>tbody>tr>th,
table.my-table-scroll>tbody>tr>td,
table.my-table-scroll>tfoot>tr>th,
table.my-table-scroll>tfoot>tr>td {
  display: inline-block;
  width: 25%;
}

</style>
