<template>
  <div class="section features features-5">
    <div class="container">
      <div class="row">
        <div class="col-md-8 text-center mx-auto">
          <h3 class="display-3">NUESTROS NIVELES</h3>
          <p class="lead">
            Elige el paquete de funcionalidades que se ajusta más a tus necesidades
          </p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-12 mt-md-5">
          <div class="row">
            <div class="col-md-6">
              <div class="info">
                <div class="pr-md-5">
                  <div
                    class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                  >
                    <i class="ni ni-atom"></i>
                  </div>
                  <h5 class="display-5">FIRMA BÁSICA</h5>
                  <p>
                    Para transformar tu proceso actual de registro.
                  </p>
                  <ul class="list-unstyled">
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Nos proporcionas los documentos a firmar y el correo y móvil de cada participante.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Cada firmante recibe una notificación para acceder a una página personalizada para revisar y firmar su documentación mediante una clave OTP.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Recibes una notificación por cada firma realizada.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Puedes acceder a los documentos firmados en cualquier momento y a las estadísticas del evento mediante un panel de control personalizado.</p>
                        </div>
                      </div>
                    </li> 
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Los participantes tienen acceso a sus documentos siempre que quieran.</p>
                        </div>
                      </div>
                    </li>                     
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info">
                <div class="pr-md-5">
                  <div
                    class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                  >
                    <i class="ni ni-money-coins"></i>
                  </div>
                  <h5 class="display-5">FIRMA BÁSICA - Opciones</h5>
                  <p>
                    Extiende a tu medida las posibilidades para optimizar el procso de registro
                  </p>
                  <ul class="list-unstyled">
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Recogida de documentos: si quieres que los participantes aporten algún tipo de documento antes de firmar la inscripción.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Reenvío de notificaciones: puedes programar el reenvío de notificaciones si el participante no ha firmado pasado un cierto tiempo.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Dispones de un panel de control en el que puedes hacer un seguimiento de las inscripciones y firmas en tu evento y acceder a los documentos firmados y aportados.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="info">
                <div class="pr-md-5">
                  <div
                    class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                  >
                    <i class="ni ni-support-16"></i>
                  </div>
                  <h5 class="display-5">PORTAL DE INSCRIPCIÓN</h5>
                  <p>Añade a los servicios de Firma Básica lo necesario para personalizar tu evento</p>
                  <ul class="list-unstyled">
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Todo lo incluido en Firma Básica y…</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Preparamos una página personalizada de inscripción a tu evento para que los participantes se inscriban y aporten los documentos necesarios.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Puedes acceder a los documentos firmados en cualquier momento y a las estadísticas del evento mediante un panel de control personalizado.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Dispondrás de un chat para comunicación con los participantes.</p>
                        </div>
                      </div>
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info">
                <div class="pr-md-5">
                  <div
                    class="icon icon-shape icon-shape-primary shadow rounded-circle mb-4"
                  >
                    <i class="ni ni-laptop"></i>
                  </div>
                  <h5 class="display-5">PORTAL PREMIUM</h5>
                  <p>Incluye todas las automatizaciones disponibles a la hora de reducir tiempos de gestión y asegurar las identidaes de los participantes .</p>
                  <ul class="list-unstyled">
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Todo lo incluido en Portal Inscripción y…</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Verificación automática de validez de documentos de identidad, seguros  y licencias federativas. No tienes que preocuparte de revisar estos documentos.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Verificación de identidad mediante comparación biometrics del participante con la foto de su documento de identidad.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex align-items-center">
                        <div>
                          <div class="mr-3">
                            <i class="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <p class="mb-0">Soporte extendido, para cualquier duda o problema que tengas.</p>
                        </div>
                      </div>
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<div>
  
  <el-table class="table table-striped table-flush align-items-center mb-0"
            :data="projects">
    <el-table-column label=""
                     min-width="100px"
                      prop="name"                     
                     scope="row">
  
    </el-table-column>
    <el-table-column label="Firma Básica"
                     min-width="60px"
                      prop="p1"
    
                     >
  
    </el-table-column>
    <el-table-column label="PORTAL INSCRIPCIÓN"
                     min-width="60px"
                     prop="p2"
                     >
    
    </el-table-column>
    <el-table-column label="PORTAL PREMIUM"
                     min-width="60px"
                     prop="p3"
                     
                     >

    </el-table-column>
 

  </el-table>
</div>


    </div>

  </div>
</template>
<script>

  import {Table, TableColumn} from 'element-ui'

export default{
      components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
  data() {
    return {
      projects:[
        {
            id: 1,
            name: 'Portal de Evento',
            p1: 'SÍ',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 2,
            name: 'Email Personalizado',
            p1: 'SÍ',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Notificaciones',
            p1: 'SÍ',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Firma OTP',
            p1: 'SÍ',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Recogida Documentos',
            p1: 'Opcional',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Recogida Datos',
            p1: 'SÍ',
            p2: '',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Verificación Documentos',
            p1: 'Opcional',
            p2: 'Opcional',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Verificación Identidad',
            p1: '',
            p2: 'Opcional',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'Panel de Control',
            p1: 'Opcional',
            p2: 'SÍ',
            p3: 'SÍ'

        }, {
            id: 3,
            name: 'App Personalizada',
             p1: '',
            p2: 'Opcional',
            p3: 'Opcional'

        }
      ],
      currentPage: 1
    }
  }
}
</script>
<style></style>
