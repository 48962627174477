<template>
  <div class="wrapper">

      <div class=" page-header-small ">
          <div class="header-body text-center mb-2">
            <div class="row justify-content-center mt-5">
                                <h3>Crear Evento</h3>

            </div>
      </div>
    </div>
    <div class="bg-secondary">
                  <div class="row justify-content-center">
                                            <h1>Crear Evento</h1>

            </div>

      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Nombre de Evento</small
            >
            <div class="form-group mb-2">
              <base-input
                alternative
                v-model="nombre"
                placeholder="Nombre"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>

          <!--   <base-input label="Single select">
            <el-select
              v-model="selected"
              filterable
              placeholder="Simple select"
            >
              <el-option
                v-for="option in options2"
                :key="option.text"
                :label="option.text"
                :value="option.id"
              >
              </el-option>
            </el-select>
          </base-input> -->
          <div class="col-md-6">
            <div class="mb-0">
              <label
                class="text-uppercase font-weight-bold"
                for="choices-single-default"
                >Tipo de evento</label
              >
              <choices-single
                v-bind:options="options2"
                v-model="selected"
                ref="childref"
              >
                <option disabled value="0">Select one</option>
              </choices-single>
            </div>
          </div>
          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Fecha de Evento</small
            >
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true }"
                class="form-control datepicker"
                v-model="dates.simple"
              >
              </flat-picker>
            </base-input>
          </div>
          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Inicio de inscripción</small
            >
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true }"
                class="form-control datepicker"
                v-model="dates.inicioins"
              >
              </flat-picker>
            </base-input>
          </div>

          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Fin de inscripción</small
            >
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true }"
                class="form-control datepicker"
                v-model="dates.finins"
              >
              </flat-picker>
            </base-input>
          </div>

          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Limite participantes</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="maxpart"
                placeholder="0"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label
                class="text-uppercase font-weight-bold"
                for="choices-single-default"
                v-b-popover.hover.top="
                  'Selecciona PREIMPRESO si hay documentos que has generado previamente con los datos de cada participante. Si sólo se van a usar plantillas de SignAndRun selecciona SÓLO PLANTILLAS. Podrás elegir las plantillas más tarde'
                "
              >
                Origen de Documentos (?)</label
              >

              <choices-single v-bind:options="optionsmodo" v-model="mododatos">
                <option disabled value="0">Select one</option>
              </choices-single>
            </div>
          </div>

          <div class="col-md-12">
            <small
              class="d-block text-uppercase font-weight-bold mb-3"
              v-b-popover.hover.right="
                'Nombres de los campos separados por comas'
              "
              >Campos adicionales</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="datosadicionales"
                placeholder="..."
                addonLeftIcon="ni ni-tag"
              ></base-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8 ml-auto">
      <div class="section"></div>
    </div>
    <div class="text-center">
      <base-button
        nativeType="reset"
        outline
        @click="gotoEventos()"
        type="primary"
        >Volver</base-button
      >

      <base-button type="primary" class="my-4" @click="create"
        >Crear Evento</base-button
      >
    </div>
    <div class="ml-5">
      <small
        >Si lo prefieres puedes usar un fichero Excel similar a este para dar de
        alta participantes</small
      >
      <a href="plantillaSAR.xlsx" class="button" download>Plantilla Excel</a>
    </div>

    <modal :show.sync="modal1">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">
          Evento creado
        </h5>
      </template>
      <div></div>
      <template slot="footer">
        <base-button type="secondary" @click="modal1 = false"
          >Cerrar</base-button
        >
        <base-button type="primary" @click="gotoEventos()"
          >Ver Eventos</base-button
        >
      </template>
    </modal>
    <div class="col-md-4">
      <modal :show.sync="modalerror">
        <div class="modal-content bg-gradient-danger">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">
              Ha ocurrido un error
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="heading mt-4">Error al crear evento!</h4>
              <p></p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-white"
              @click="modalerror = false"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-link text-white ml-auto"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//import BaseDropdown from "@/components/BaseDropdown";
import ChoicesSingle from "@/components/SingleSelect";
import UserService from "../services/user.service";
import { Select, Option } from "element-ui";
import Modal from "@/components/Modal";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";

export default {
  name: "CrearEvento",
  props: ["id"],
  components: {
    flatPicker,
    ChoicesSingle,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
  },
  directives: {
    BTooltip: VBTooltip,
    BPopover: VBPopover,
  },
  data() {
    return {
      dates: {
        simple: "",
        range: "2020-07-17 to 2020-07-19",
        datetime: "2020-07-31 12:00",
        inicioins: "",
        finins: "",
      },
      modal1: false,
      modalerror: false,
      maxpart: 0,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      v1: "cccccccc",
      nombre: "",
      selected: 0,
      options2: [
        { id: 1, text: "Foocccccccbar" },
        { id: 2, text: "Is Great" },
        { id: 3, text: "Pick One" },
      ],
      mododatos: 0,
      optionsmodo: [
        { id: 1, text: "SÓLO PLANTILLAS" },
        { id: 2, text: "PREIMPRESO" },
      ],
      datosadicionales: "",
    };
  },

  beforeMount() {
    this.v1 = "xxxxxxxx";
    this.gettipos();
  },
  methods: {
    gettipos() {
      const self = this;

      UserService.getTiposEvento().then(
        (response) => {
          var opts = response.data;
          self.v1 = "sssssssss";
          for (var i = 0; i < response.data.count; i++) {
            self.options2[i] = {};
            self.options2[i].id = response.data.results[i].id;
            self.options2[i].text = response.data.results[i].nombre;
          }
          this.$refs.childref.setChoices();
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          // alert(this.content);
        }
      );
    },
    create() {
      const self = this;
      var evento = {
        nombre: this.nombre,
        tipo: this.selected,
        fecha: this.dates.simple,
        fechainicio: this.dates.inicioins,
        fechafin: this.dates.finins,
        maxpart: this.maxpart,
        mododatos: this.mododatos,
        datosadicionales: this.datosadicionales,
      };
      UserService.createevento(evento).then(
        (user) => {
          self.modal1 = true;
          return Promise.resolve(user);
        },
        (error) => {
          self.modalerrorr = true;

          return Promise.reject(error);
        }
      );
    },
    gotoEventos: function(uuid) {
      this.$router.push({
        path: "/listaeventos2",
        query: { id: uuid },
      });
    },
  },
};
</script>
<style></style>
