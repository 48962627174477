<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-12 mt-2">
          <VueSignaturePad
            id="signature"
            width="100%"
            height="200px"
            ref="signaturePad"
            :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-2">
          <button class="btn btn-outline-secondary" @click="undo">Deshacer</button>
        </div>
                <div class="col-3 mt-2"></div>

        <div class="col-3 mt-2">
       <!--   <button class="btn btn-outline-primary" @click="save">Listo </button> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    options: {
      penColor: "#c0f"
    }
  }),
  methods: {
    undo() {
             this.$store.commit("setGraphoimg", null);

      this.$refs.signaturePad.undoSignature();
    },
    save() {
       this.$store.commit("setGraphoimg", null);
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      console.log(isEmpty);
      console.log(data);
        this.$store.commit("setGraphoimg", data);
        this.$emit("closemod",data);
    },
    change() {
      this.options = {
        penColor: "#00f"
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f"
      };
    }
  }
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
