// eslint-disable-next-line no-unused-vars

import Vue from "vue";
import App from "./App.vue";
//import router from "./router";
import router from "./starterRouter";
import {store} from './store'
import VeeValidate from 'vee-validate';
import IdleVue from "idle-vue";
import moment from "moment";
import axios from 'axios';

import Argon from "./plugins/argon-kit";
import vPlayBack from 'v-playback';
import VueSignature from "vue-signature-pad";

Vue.use(VueSignature);

Vue.use(vPlayBack);
Vue.config.productionTip = false;
Vue.use(Argon);

Vue.use(VeeValidate);
Vue.prototype.$http = axios;
Vue.prototype.moment = moment;
Vue.prototype.window = window;

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000, // 20 Minutes 1200000
  store,
  startAtIdle: false

});

Vue.prototype.$store2 = store


new Vue({
  router,
  store,
  render: h => h(App),

  beforeCreate: function() {
    // Adding some custom rules
    const _this = this;
    this.$store.commit("setDocumentsCompleted", false);

    // Configuring the base URL
    // Add a request interceptor
    this.$store.state.auth.status.loggedIn
    _this.$http.interceptors.request.use(
      function(config) {
        // Do something before request is sent
        if (config.url.indexOf("public") != -1){
          return config;
        }
        if (!config.url.includes("token") && !config.url.includes("refresh")) {
          config.headers.Authorization = "Bearer " + _this.$store.state.token;
        } else if (config.url.includes("refresh")) {
          if (_this.$store.getters.refreshToken === "") {
            config.headers.Authorization =
              "Bearer " + sessionStorage.getItem("rt");
          } else {
            config.headers.Authorization =
              "Bearer " + _this.$store.getters.refreshToken;
          }
        }
        if (config.url.indexOf("getimagebypathbase64") === -1)
          _this.$store.commit("isLoading", true);
        return config;
      },
      function(error) {
        _this.$store.commit("isLoading", false);
        return Promise.reject(error);
      }
    );
    // Add a response interceptor
    _this.$http.interceptors.response.use(
      function(response) {
        _this.$store.commit("isLoading", false);
        return response;
      },
      function(error) {
        if (error.response && error.response.status === 422) {
          if(  _this.$store.getters.validuser.length > 0 ){
            var payload = {};
            payload.userName = _this.$store.getters.validuser;
            payload.password = _this.$store.getters.validpassword;
            _this.$store.dispatch('auth/login', this.user).then(
              () => {
                self.$router.push('/account');
              },
              error => {
                self.loading = false;
                self.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
              }
            );
          
          }
          _this.$router.push({name: 'views/LoginPage'})
          return Promise.reject(error);

        }
        else if (error.response && error.response.status === 403) {
            _this.$store.commit("setValiduser", null) 
          _this.$router.push({name: 'login'})
          return Promise.reject(error);

        }
        if (error.response && error.response.status === 401) {
          // We need to refresh the token.
          _this.$router.push({name: 'login'})
          return Promise.reject(error);
  
        }

        else {
          _this.$store.commit("isLoading", false);
          return Promise.reject(error);
        }
      }
    );
  }



}).$mount("#app");



