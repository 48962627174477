<template>
  <div class="wrapper">
    <div class="section-shaped skew-separator skew-mini">
      <div class="page-header page-header-mini header-filter">
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">Nuevo Participante</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-secondary">
      <div class="container">
                <div class="row">
                  <h4 >Evento: {{evento.eventodef.nombre}}</h4>
                </div>
        <div class="row">
          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Nombre</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="nombre"
                placeholder="Nombre"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>
          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Apellido 1</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="apellido1"
                placeholder="Apellido1"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>
          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Apellido 2</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="apellido2"
                placeholder="Apellido2"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>

          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >NIF/NIE/Pasaporte</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="nif"
                placeholder="NIF"
                addonLeftIcon="ni ni-credit-card"
              ></base-input>
            </div>
          </div>

          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Email</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="email"
                placeholder="email"
                addonLeftIcon="ni ni-email-83"
              ></base-input>
            </div>
          </div>

          <div class="col-md-6">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Teléfono </small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="cellphone"
                placeholder="Teléfono"
                addonLeftIcon="ni ni-mobile-button"
              ></base-input>
            </div>
          </div>



 
          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Fecha de Nacimiento</small
            >
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                slot-scope="{ focus, blur }"
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true }"
                class="form-control datepicker"
                v-model="dates.simple"
              >
              </flat-picker>
            </base-input>
          </div>
          

          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >RFID</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="rfid"
                placeholder="0"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>

          <div class="col-md-4">
            <small class="d-block text-uppercase font-weight-bold mb-3"
              >Dorsal</small
            >
            <div class="form-group mb-0">
              <base-input
                alternative
                v-model="dorsal"
                placeholder="0"
                addonLeftIcon="ni ni-circle-08"
              ></base-input>
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3">
              <label
                class="text-uppercase font-weight-bold"
                for="choices-single-default"
                >Seguro</label><label class="small">  -- Se deberá aportar comprobante si aplica</label
              >
              
              <choices-single
                v-bind:options="optionsseguro"
                v-model="modoseguro"
               
              >
                <option disabled value="0">Select one</option>
              </choices-single>
            </div>


        </div>
      </div>
    </div>


    <div class="text-center">
              <base-button
                nativeType="reset"
                outline
                @click="gotoEventos()"
                type="primary"
                >Volver</base-button
              >

      <base-button type="primary" class="my-4" @click="create"
        >Añadir Participante</base-button
      >
    </div>

                    <modal :show.sync="modal1">
                      <template slot="header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Participante añadido 
                        </h5>
                      </template>
                      <div>
                      </div>
                      <template slot="footer">
                        <base-button type="secondary" @click="modal1 = false"
                          >Cerrar</base-button>
                          <base-button type="primary" @click="gotoEventos()"
                          >Ver  Eventos</base-button>                        
                      </template>
                    </modal>  <div class="col-md-4">
      <modal :show.sync="modalerror">
        <div class="modal-content bg-gradient-danger">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-notification">Ha ocurrido un error</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="py-3 text-center">
              <i class="ni ni-bell-55 ni-3x"></i>
              <h4 class="heading mt-4">Error al crear participante!</h4>
              <p></p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-white"   @click="modalerror=false" >Cerrar </button>
            <button type="button" class="btn btn-link text-white ml-auto" data-dismiss="modal">Close</button>
          </div>
        </div>
    </modal>
  </div>
    </div>

</div>

</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//import BaseDropdown from "@/components/BaseDropdown";
import ChoicesSingle from "@/components/SingleSelect";
import UserService from "../services/user.service";
import Modal from "@/components/Modal";

export default {
  name: "CrearRunner",
  props: ["id"],
  components: {
    flatPicker,
    ChoicesSingle,
  
    Modal
  },
  data() {
    return {
      dates: {
        simple: "",
        range: "2020-07-17 to 2020-07-19",
        datetime: "2020-07-31 12:00",
        inicioins: "",
        finins: "",
      },
      modal1: false,
      modalerror:false,
      maxpart:0,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      v1: "cccccccc",
      nombre: "",
      email: "",
      nif: "",
      apellido1:"",
      apellido2:"",
      selected: 0,
      modoseguro: 0,
      rfid: '',
      dorsal: '',
      evento:{},
      participacion:{},
      campos:[],
      optionsseguro:[
        {id: 0, text:"SIN SEGURO"},     
        {id: 1, text:"SEGURO ANUAL"},
        {id: 2, text:"LICENCIA"},

      ],
      form : [
 
      ],
      theme : 'light',
           button : {
        title : '',
        class : 'btn btn-disabled',
      },
      formData : {}, 
    };
  },

  mounted() {
    this.uuid = this.$route.params.id;
    this.$store.commit("setEventouuid", this.uuid);
    this.loadData();
  },
  methods: {
      loadData() {
      const self = this;
      self.isLoading = true;
      let url = process.env.VUE_APP_BASE_URI + "api/laptracker/laptrackerevento/?id=" + this.uuid;
      self.$http.get(url).then(
        function(success) {
          self.evento = success.data.results[0];
 
          if( self.evento.datosadicionales != null){
            self.campos = self.evento.datosadicionales.split(',');
          }
          self.isLoading = false;
          self.formData= {};
          for( var i = 0; i < self.campos.length; i++){
            var newfield = {};
            newfield.name= self.campos[i];
            newfield.class =  'form-control';
                     newfield.column = self.campos[i];
            newfield.type = 'text';
            newfield.value = "";
            newfield.placeholder="";
                      self.form.push(newfield);

          }

          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del evento  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );      
    },


    create() {
      const self = this;
      var parti = {
        evento: this.uuid,
        nombre: this.nombre,
        apellido1: this.apellido1,
        apellido2: this.apellido2,
        fechanac: this.dates.simple,
        nif:this.nif,
        email:this.email,
        cellphone:this.cellphone,
        rfid:this.rfid,
        dorsal:this.dorsal,
        seguro:this.modoseguro,
      };
      UserService.createrunner(parti).then(
        (user) => {
          self.modal1=true;
          return Promise.resolve(user);
        },
        (error) => {
          self.modalerrorr=true;

          return Promise.reject(error);
        }
      );
    },
    gotoEventos: function(uuid) {
      this.$router.push({
        path: "/listaeventos2",
        query: { id: uuid },
      });
    },     
  },
};
</script>
<style></style>
