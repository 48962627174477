<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    ></div>
    <div class="bg-secondary">
      <div class="row">
        <div class="col-md-3">
          <div class="section">
            <!-- User Information -->

            <!-- User Information -->
            <!-- Profile Sidebar -->
            <section>
              <br />
              <tabs
                v-model="accountTab"
                vertical
                tabNavWrapperClasses="col-md-12 text-center"
                no-content-space
              >
                <tab-pane label="Datos"></tab-pane>
                <tab-pane label="Documentos"></tab-pane>
                <tab-pane label="Aportados"></tab-pane>
              </tabs>
            </section>
            <!-- End Profile Sidebar -->
            <!-- Profile Completion -->
            <br /><br /><br />
            <div></div>
            <!-- End Profile Completion -->
          </div>
        </div>
        <div class="col-md-9 ml-auto">
          <div class="section">
            <div class="tab-content">
              <div v-if="accountTab === 'Datos'" class="tab-pane active">
                <div>
                  <header>
                    <h5 class="text-uppercase">Datos de la firma</h5>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Nombre:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.signer.name
                      }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Apellidos:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.signer.lastName
                      }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Email:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.signer.user.email
                      }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Teléfono:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.signer.cellphone
                      }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">NIF:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.signer.dni
                      }}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Estado:</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <label class="labels" for="#firstName">{{
                        firma.strstate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="accountTab === 'Documentos'" class="tab-pane active">
                <header>
                  <h2 class="text-uppercase mb-0"></h2>
                </header>
                <hr class="line-primary" />
                <div>
                  <div
                    v-for="doc in documents"
                    :key="doc.reference"
                    v-bind="doc"
                  >
                  <div class="mt-2">
                    <base-button
                      type="primary"
                      :size="22"
                      @click="loadPdf(firma.uuid, doc.pdf)"
                      
                    >
                      Ver {{ doc.docname }}
                    </base-button>
                    <base-button
                      type="primary"
                      @click="window.open(doc.pdf)"
                      
                    >
                      Descargar
                    </base-button>
                  </div>
                  </div>

                  <modal :show.sync="modal0">
                    <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Ver documento
                      </h5>
                    </template>
                    <div>
                      {{ currentPage }} / {{ pageCount }}
                      <v-btn
                        class="btn btn-primary btn-sm"
                        @click="pageprev"
                        style="font-size:10"
                        >Pag-</v-btn
                      >

                      <v-btn class="btn btn-primary btn-sm" @click="pagenext"
                        >Pag+</v-btn
                      >
                      <button @click="rotate += 90">&#x27F3;</button>
                      <button @click="rotate -= 90">&#x27F2;</button>
                      <button @click="$refs.pdf.print()">print</button>
                      <div style="width: 100%">
                        <div
                          v-if="loadedRatio > 0 && loadedRatio < 1"
                          style="background-color: green; color: white; text-align: center"
                          :style="{ width: loadedRatio * 100 + '%' }"
                        >
                          {{ Math.floor(loadedRatio * 100) }}%
                        </div>
                        <pdf
                          v-if="modal0"
                          ref="pdf"
                          style="border: 1px solid red"
                          :src="pdfdata"
                          :page="page"
                          :rotate="rotate"
                          @password="password"
                          @progress="loadedRatio = $event"
                          @error="error"
                          @num-pages="pageCount = $event"
                          @page-loaded="currentPage = $event"
                          @link-clicked="page = $event"
                        ></pdf>
                      </div>
                    </div>
                    <template slot="footer">
                      <base-button type="secondary" @click="modal0 = false"
                        >Cerrar</base-button
                      >
                    </template>
                  </modal>
                </div>
              </div>

              <div v-if="accountTab === 'Aportados'" class="tab-pane active">
                <header>
                  <h2 class="text-uppercase mb-0"></h2>
                </header>
                <hr class="line-primary" />
                <div>
                  <div
                    v-for="doc in uploadeddocuments"
                    :key="doc.reference"
                    v-bind="doc"
                  >
                    <base-button
                      type="primary"
                      @click="loadPdfUp(firma.uuid, doc.filedata)"
                      class=""
                    >
                      Ver {{ doc.description }}
                    </base-button>
                    <base-button
                      type="primary"
                      @click="window.open(doc.filedata)"
                      classs="mt-2"
                    >
                      Descargar
                    </base-button>
                  </div>

                  <modal :show.sync="modal1">
                    <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Documento
                      </h5>
                    </template>
                    <div>
                      {{ currentPage }} / {{ pageCount }}
                      <v-btn
                        class="btn btn-primary btn-sm"
                        @click="pageprev"
                        style="font-size:10"
                        >Pag-</v-btn
                      >

                      <v-btn class="btn btn-primary btn-sm" @click="pagenext"
                        >Pag+</v-btn
                      >
                      <button @click="rotate += 90">&#x27F3;</button>
                      <button @click="rotate -= 90">&#x27F2;</button>
                      <button @click="$refs.pdf.print()">print</button>
                      <div style="width: 100%">
                        <div
                          v-if="loadedRatio > 0 && loadedRatio < 1"
                          style="background-color: green; color: white; text-align: center"
                          :style="{ width: loadedRatio * 100 + '%' }"
                        >
                          {{ Math.floor(loadedRatio * 100) }}%
                        </div>
                        <pdf
                          v-if="modal1"
                          ref="pdf"
                          style="border: 1px solid red"
                          :src="pdfdata"
                          :page="page"
                          :rotate="rotate"
                          @password="password"
                          @progress="loadedRatio = $event"
                          @error="error"
                          @num-pages="pageCount = $event"
                          @page-loaded="currentPage = $event"
                          @link-clicked="page = $event"
                        ></pdf>
                      </div>
                    </div>
                    <template slot="footer">
                      <base-button type="secondary" @click="modal1 = false"
                        >Cerrar</base-button
                      >
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/ImageUpload";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
//import ChoicesSingle from "@/components/SingleSelect";
import Tags from "@/components/Tags";
import BaseAlert from "@/components/BaseAlert";
import pdf from "vue-pdf-signature";
import axios from "axios";
import Modal from "@/components/Modal";
import UserService from "../services/user.service";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseButton from "../components/BaseButton.vue";

export default {
  name: "FirmaDetail",
  props: ["id"],
  bodyClass: "account-settings",
  components: {
    Tabs,
    TabPane,

    pdf,
    Modal,
    BaseButton,
  },

  data() {
    return {
      uuid: "",
      accountTab: "Datos",
      isLoading: false,
      evento: {},
      firma: {},
      documents: [],
      uploadeddocuments: [],
      pdfdata: "",
      selected: 2,
      modal0: false,
      modal1: false,
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      readerpdf: "",
      readerpdfadic: "",
      readerxls: "",
      currentPage: 0,
      pageCount: 0,
      page: 1,
      rotate: 0,
      progress: 0,
      estado: [
        { id: 1, text: "Programado" },
        { id: 10, text: "En curso" },
        { id: 20, text: "Finalizado" },
        { id: 30, text: "Cancelado" },
      ],

      switches: {
        off: false,
        on: true,
      },
      radio: {
        radio1: "radio1",
      },
    };
  },

  mounted() {
    this.uuid = uuid;
    this.showFirma();
    EventBus.$on("setFirmaUuid", (uuid) => {
      alert(uuid);
      this.uuid = uuid;
      this.showFirma();
      //    this.showDocuments();
    });
  },

  methods: {
    setUuid(uuid) {
      this.uuid = uuid;
      this.showFirma();
      this.showDocuments();
      this.showDocuments2();
    },

    showFirma: function() {
      const self = this;
      self.isLoading = true;
      this.firma = {};
      let url2 =
        process.env.VUE_APP_BASE_URI +
        "api/portafirmas/signaturesigner?flow=" +
        self.uuid;
      self.$http.get(url2).then(
        function(success) {
          self.participaciones = [];
          self.firma = success.data.results[0];
          if (self.firma.state.id == 0) {
            self.firma.strstate = "PENDIENTE";
          }
          if (self.firma.state.id == 1) {
            self.firma.strstate = "ACCESO";
          }
          if (self.firma.state.id == 2) {
            self.firma.strstate = "FIRMADA";
          }
          if (self.firma.state.id > 2) {
            self.firma.strstate = "CANCELADA";
          }
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar los datos de firma.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },

    showDocuments2: function() {
      const self = this;
      self.isLoading = true;
      this.firma = {};
      let url2 =
        process.env.VUE_APP_BASE_URI +
        "api/portafirmas/signaturesigner?flow=" +
        self.uuid;
      self.$http.get(url2).then(
        function(success) {
          var res1 = success.data.results[0];

          let url3 =
            process.env.VUE_APP_BASE_URI +
            "api/portafirmas/uploaddocsignaturesigner?signsigner__id=" +
            res1.id;
          self.$http.get(url3).then(
            function(success) {
              self.participaciones = [];
              self.uploadeddocuments = success.data.results;

              self.isLoading = false;
            },
            function(error) {
              self.isLoading = false;
              swal.fire({
                title: "Error!",
                text: "No se ha podido cargar los datos de firma.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "warning",
              });
            }
          );
          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar los datos de firma.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },

    showDocuments: function() {
      const self = this;
      self.isLoading = true;
      this.firma = {};
      let url2 =
        process.env.VUE_APP_BASE_URI +
        "api/portafirmas/document?flow=" +
        self.uuid;
      self.$http.get(url2).then(
        function(success) {
          self.participaciones = [];
          self.documents = success.data.results;

          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar los datos de firma.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );

      let url3 =
        process.env.VUE_APP_BASE_URI +
        "api/portafirmas/uploaddocsignaturesigner?signsigner__id=" +
        self.uuid;
      self.$http.get(url3).then(
        function(success) {
          self.participaciones = [];
          self.uploadeddocuments = success.data.results;

          self.isLoading = false;
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar los datos de firma.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },

    loadPdf(opuuid, docurl) {
      const self = this;

      self.pdfdata = docurl;
      self.modal0 = true;
    },


    loadPdfUp(opuuid, docurl) {
      const self = this;

      self.pdfdata = docurl;
      self.modal1 = true;
    },    

    loadPdfOld(opuuid, docuuid) {
      const self = this;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/getdocumentb64/?opuuid=" +
        opuuid +
        "&docuuid=" +
        docuuid;
      self.$http.get(url).then(
        function(success) {
          self.pdfdata = "data:application/pdf;base64," + success.data;
          self.modal0 = true;
        },

        function(error) {
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar el documento.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },

    updateFichero: function(tipo) {
      var datos = self.readerpdf;
      if (this.readerpdf.split(",")[0].indexOf("base64") >= 0) {
        datos = this.readerpdf.split(",")[1];
      }

      var data = {};
      if (tipo == 1) {
        data = {
          evento: this.uuid,
          tipofichero: "plantilla",
          file: datos,
        };
      }

      UserService.updatefileinevento(data).then(
        (user) => {
          alert("Actualizado fichero");
        },
        (error) => {
          alert("Error");
        }
      );
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;
    },

    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadPdf(opuuid, url) {
      const self = this;

      UserService.getPdfNob64Url(opuuid, url).then(
        (data) => {
          self.forceFileDownload(data, "documento.pdf");
        },
        (error) => {
          swal.fire({
            title: "Error!",
            text: "No se ha podido cargar el documento.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
    pagenext() {
      this.currentPage++;
      if (this.currentPage > this.pageCount) this.currentPage = this.pageCount;
    },
    pageprev() {
      this.currentPage--;
      if (this.currentPage < 1) this.currentPage = 1;
    },
    zoomplus() {
      this.zoom += 20;
      this.pdfstyle = "width:" + this.zoom + "% ";
    },
    zoomminus() {
      this.zoom -= 20;
      this.pdfstyle = "width:" + this.zoom + "% ";
    },
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}
</style>
