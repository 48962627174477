<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="border-4 card-header" style="padding-top:20px">
        <h3 class="mb-0">Tiempos </h3>
      </div>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="border-4 card-headercol-lg-10" style="padding-top:20px">
        <h3 class="mb-0">Tiempos {{evento.eventodef.nombre}}</h3>
      </div>
      <div class="row col-12">
        <div class="col-lg-2">
                                 <label class="file-select">
                            <div class="select-button small "  style="background-color:blue;">
                              <span v-if="value"
                                >Fichero : {{ value.name }}</span
                              >
                              <span v-else>Elegir Excel</span>
                            </div>
                            <input
                              type="file"
                              @click="tipoimagen = 'excel'"
                              @change="handleFileChange"
                            />
                          </label>
        </div>
        <div class="col-lg-2">
          <base-button
            nativeType="reset"
            outline
            @click="updateFile()"
            type="primary"
            >Cargar Excel</base-button
          >
        </div>
                <div class="col-lg-4">
          <base-button
            nativeType="reset"
            outline
            @click="gotoNewRunner()"
            type="primary"
            >Nuevo participante</base-button
          >
        </div>
        <div>
               <select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </option>
              </select>

        </div>
        <div class="col-lg-4 align-items-end">
          <base-input
            v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Buscar..."
          >
          </base-input>
        </div>
      </div>
      <div></div>
      <el-table
        class="table-responsive table-flush table align-items-center"
        header-row-class-name="thead-light"
        :data="queriedData"
        row-key="id"
        @selection-change="selectionChange"
      >
        >

        <el-table-column label="Dorsal" min-width="50px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.dorsal
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>


        <el-table-column
          label="RFID"
          min-width="80px"
          prop="name"
          sortable
        >
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.rfid
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <!--      <el-table-column label="Estado" prop="budget" min-width="60px" sortable>
          <template v-slot="{ row }">
            <div
              class="media align-items-center"
              style="font-size: 1.3rem;  padding-top:5px; padding-left:50px;color: green "
            >
              <i :class="`ni ni-${row.icon}`"></i>
            </div>
          </template>
        </el-table-column>  -->

        <el-table-column label="DNI" min-width="80px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.user.username
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Nombre"
          min-width="170px"
          prop="status"
          sortable
        >
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.user.first_name
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>



        <el-table-column
          label="Apellidos"
          min-width="170px"
          prop="status"
          sortable
        >
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.user.last_name
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Tiempo" min-width="90px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.mejorvueltadt
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Nivel" min-width="60px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.niveldef.nombrenivel
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column min-width="180px">
          <template v-slot="{ row }">
            <span
              class="btn btn-sm btn-icon-only text-bold"
              style="font-size:1.2rem; color: blue; vertical-align:center"
              @click="gotoDetail(row.id)"
            >
              <i class="ni ni-zoom-split-in mt-2"></i>
            </span>
            <el-dropdown trigger="click" class="dropdown">
              <span
                class="btn btn-sm btn-icon-only text-bold"
                style="font-size:1.2rem; color: red; vertical-align:center"
              >
                <i class="ni ni-settings-gear-65 mt-2"></i>
              </span>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                slot="dropdown"
              >
                <a class="dropdown-item" href="#" @click="gotoDetail(row.id)"
                  >Detalles</a
                >
                <a class="dropdown-item" href="#">Cancelar evento</a>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      >
      </base-pagination>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option,
} from "element-ui";
import Spinner from "../components/Spinner.vue";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";
import UserService from "../services/user.service";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";


export default {
  mixins: [clientPaginationMixin],

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      filter: "",
      evento: {},
      runners: [],
      tableData: [],
      selectedRows: [],
      selectedExcel: null,
      currentPage: 1,
      propsToSearch: ["nombre", "fecha", "estado"],
      colores: "green",
      isLoading: false
    };
  },
  mounted() {
    this.keys=['rfid','dorsal'];
    this.uuid = this.$route.params.id;
  //  this.$store.commit("setEventouuid", this.uuid);
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      let url = process.env.VUE_APP_BASE_URI + "api/laptracker/laptrackerevento/" + this.uuid;
      self.$http.get(url).then(
        function(success) {
          self.evento = success.data;


          let url2 = process.env.VUE_APP_BASE_URI + "api/laptracker/eventorunner/?eventodef=" + self.uuid;
          self.$http.get(url2).then(          
            function(success){
              self.runners = success.data.results;

              self.tableData = self.runners;
              self.isLoading = false;
            },
   
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos  ";
          self.snackcolor = "error";
          self.snackresult = true;
        });
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
        
      );
    },
    fileAdded(file) {
      let vv = file;
      this.inputs.fileSingle = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.inputs.fileSingle);
      reader.onload = () => {
        console.log(reader.result);
      };
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    gotoDetail: function(uuid) {
      this.$router.push({
        path: "/eventodetail/" + uuid,
        query: { id: uuid },
      });
    },
        gotoNewRunner: function(uuid) {
      this.$router.push({
        path: "/crearrunner/"+this.uuid ,
        query: { id: this.uuid },
      });
    },
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },
        handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
      this.selectedExcel = e.target.files[0];
    },

    updateFile() {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedExcel);
      reader.onload = () => {
        self.readerimg = reader.result;
        var datos = self.readerimg;
        if (self.readerimg.split(",")[0].indexOf("base64") >= 0) {
          datos = self.readerimg.split(",")[1];
        }
        var data = {
          evento: this.uuid,
          tipofichero: 'xlsparticipantes',
          file: datos,
        };
        UserService.updateexcelinrodada(data).then(
          (user) => {
            swal.fire({
              title: "Fichero añadido",
              text: "Se ha añadido el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "success",
            });
            self.isLoading = false;
            this.loadData();
          },
          (error) => {
            swal.fire({
              title: "Fichero no añadido",
              text: "Error al añadir el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-error",
              icon: "error",
            });
            self.isLoading = false;
            self.readerxls = "";
          }
        );
      };
    },
  },
};
</script>
