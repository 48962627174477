var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"card col-lg-10 col-md-10 mx-auto text-center"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.eventos}},[_c('el-table-column',{attrs:{"label":"Project","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('a',{staticClass:"avatar rounded-circle mr-3",attrs:{"href":"#"}},[_c('img',{attrs:{"alt":"Image placeholder","src":row.img}})]),_c('div',{staticClass:"media-body"},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])])]}}])}),_c('el-table-column',{attrs:{"label":"Budget","prop":"budget","min-width":"140px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Status","min-width":"170px","prop":"status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:("bg-" + (row.statusType))}),_c('span',{staticClass:"status"},[_vm._v(_vm._s(row.status))])])]}}])}),_c('el-table-column',{attrs:{"label":"Completion","prop":"completion","min-width":"260px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"completion mr-2"},[_vm._v(_vm._s(row.completion)+"%")]),_c('div',[_c('base-progress',{attrs:{"type":row.statusType,"value":row.completion}})],1)])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Action"+_vm._s(row.completion))]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Another action")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_vm._v("Something else here")])])],1)]}}])})],1),_c('div',{staticClass:"d-flex"},[_c('card',{staticClass:"col-lg-5 col-md-5"},[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Dropzone")]),_c('dropzone-file-upload',{attrs:{"multiple":""},model:{value:(_vm.inputs.fileMultiple),callback:function ($$v) {_vm.$set(_vm.inputs, "fileMultiple", $$v)},expression:"inputs.fileMultiple"}})],1),_c('div',{staticClass:"col-lg-2 col-md-2"}),_c('card',{staticClass:"col-lg-5 col-md-5"},[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Pdf Dropzone")]),_c('h5',{staticClass:"card-title"},[_vm._v("Card title")]),_c('pdf-dropzone-file-upload',{on:{"changeFile":function($event){return _vm.fileAdded($event)}},model:{value:(_vm.inputs.fileSingle),callback:function ($$v) {_vm.$set(_vm.inputs, "fileSingle", $$v)},expression:"inputs.fileSingle"}})],1)],1),_c('div',{staticClass:"card-footer py-4 d-flex justify-content-end"},[_c('base-pagination',{attrs:{"total":50},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Light table")])])}]

export { render, staticRenderFns }