<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="b" style="padding-top:20px">
        <h3 class="mb-0">Eventos</h3>
      </div>
    </div>
    <div
      class="card col-lg-10 col-md-10 mx-auto text-center"
      style="padding-top:20px"
    >
      <div class="border-4 card-headercol-lg-10" style="padding-top:20px">
        <h3 class="mb-0">Próximos Eventos </h3>
      </div>

      <div class="row">
            <div v-for="evento in eventos" :key="evento.id" class="col-md-4 col-sm-6 col-lg-3">

      <div class="card-deck ">
        <card >
          <template v-slot:image>
            <img
              class="card-img-top"
              src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
              alt="Card image cap"
            />
          </template>
          <h5 class="card-title"><label v-text="evento.tipo"></label></h5>
          <template slot="body">
            <h5 class="card-title" v-text="evento.nombre"></h5>
            <h6 class="card-title" v-text="evento.localizacion"></h6>
            <p class="card-text small">Fecha</p>
            <div>
              <p
                class="card-text"
                v-text="moment(evento.fecha).format('DD/MM/YYYY ')"
              ></p>
              <p class="card-text small">Fin de inscripción</p>

              <p
                class="card-text"
                v-text="moment(evento.fininscripcion).format('DD/MM/YYYY ')"
              ></p>
            </div>

            <base-button
              tag="a"
              @click="gotoParti(evento.id)"
              class="btn btn-white"
              >Quiero Pre-Registrarme</base-button
            >
            <br />
          </template>
          <base-button
            nativeType="reset"
            outline
            @click="gotoEventoPublic(evento.id)"
            type="primary"
            >Ver Detalles</base-button
          >
          <p class="card-text">
            <small class="text-muted">Todavía puedes inscribirte</small>
          </p>
        </card>
      </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Select,
  Option,
} from "element-ui";
import Spinner from "../components/Spinner.vue";
import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";

export default {
  mixins: [clientPaginationMixin],

  components: {
    Spinner,
  },
  data() {
    return {
      inputs: {
        fileSingle: [],
        fileMultiple: [],
      },
      filter: "",
      eventos: [],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      propsToSearch: ["nombre", "fecha", "estado"],
      colores: "green",
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url = process.env.VUE_APP_BASE_URI + "api/v1/listpublicevents/";
      self.$http.get(url).then(
        function(success) {
          var eventost = success.data;

          for (var i = 0; i < eventost.length; i++) {
            var linea = {};

            linea.id = eventost[i].id;
            linea.nombre = eventost[i].nombre;

            linea.fecha = eventost[i].fecha;
            linea.fininscripcion = eventost[i].fininscripcion;
            linea.maxparticipantes = eventost[i].maxparticipantes;
            linea.localizacion = eventost[i].localizacionnombre;

            self.eventos.push(linea);
          }
          self.tableData = self.eventos;
          self.isLoading = false;
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },

    gotoParti: function(uuid) {
      this.$router.push({
        path: "/crearpartipublic/" + uuid,
        query: { id: uuid },
      });
    },
    gotoEventoPublic: function(uuid) {
      this.$router.push({
        path: "/eventoshow/" + uuid,
        query: { id: uuid },
      });
    },
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },
  },
};
</script>
