<template>
  <header class="header-5 sticky-top ">

    <base-nav class="navbar-main  bg-white sticky-top " type="" effect="white" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/featuresinit">
        <a href="javascript:;" class="navbar-brand text-primary" @click="gotoInit2()"
          >SIGN AND RUN</a
        >
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <img src="img/brand/blue.png" />
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <a class="nav-link" @click="gotoInit()" href="javascript:;" >
            Qué es
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="gotoSections()" href="javascript:;">
            Cómo funciona
          </a>
        </li>
      
        <li class="nav-item">
          <a class="nav-link"   href="https://organizadorsar.signandrun.com">
            Acceso Organizadores
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="gotoContact()" href="javascript:;">
            Contacto 
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="gotoParti()" href="javascript:;">
            Eventos 
          </a>
        </li>

      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item">
          <a class="nav-link" href="https://twitter.com/signandrun">
            <i class="fa fa-twitter"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.facebook.com/signandrun">
            <i class="fa fa-facebook-square"></i>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://www.instagram.com/signandrun"
          >
            <i class="fa fa-instagram"></i>
          </a>
        </li>
      </ul>
    </base-nav>
  
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton
  },
 data() {
    return {
 
      maxpart:0,
      multiLevel: false,
      multiLevel2: false,
      multiLevel3: false,
      v1: "cccccccc",
      nombre: "",
      selected: 0,
   
    };
  },

  beforeMount() {
 
  },
  methods: {
  
    gotoContact: function(uuid) {
      this.$router.push({
        path: "/contact-uspub",
        query: { id: uuid },
      });
    }, 
    gotoSections: function(uuid) {
      this.$router.push({
        path: "/sections",
        query: { id: uuid },
      });
    },   
    gotoInit: function(uuid) {
      this.$router.push({
        path: "/landing-page",
        query: { id: uuid },
      });
    },   
       gotoInit2: function(uuid) {
      this.$router.push({
        path: "/featuresinit",
        query: { id: uuid },
      });
    },   
        gotoPaginated: function() {
      this.$router.push({
        path: "/paginated",
      });
    },  
        gotoLogin: function() {
      this.$router.push({
        path: "/login",
      });
    },  
    gotoCrearEventos: function(uuid) {
      this.$router.push({
        path: "/crearevento",
        query: { id: uuid },
      });
    },       
    gotoParti: function(uuid) {
      this.$router.push({
        path: "/listaeventoslibres",
        query: { id: uuid },
      });
    }, 
  },
};
</script>
<style></style>
