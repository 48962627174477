<template>
  <div class="wrapper">
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>

    <div class="  col-lg-12 ">
      <div class="mt-5">
       
        <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 mt-5">
                <h1 > Evento</h1>
              </div>
            </div>
          
        </div>
      </div>
    </div>
    <div class="bg-secondary col-xl-12 col-lg-12 col-md-12">
      <div class="container bg-white col-xl-11 col-lg-11 col-md-11  mb-0">
        <div class="row">
          <div class="col-md-2 bg-secondary">
            <div class="section">
              <base-button
                nativeType="reset"
                outline
                @click="gotoEventos()"
                type="primary"
                >Volver</base-button
              >
              <!-- User Information -->
              <section class="text-center">
                <img
                  :src="evento.imagen1"
                  alt="preview"
                  width="150"
                  class="round rounded-circle"
                />

                <h3 class="title mt-2">{{ evento.nombre }}</h3>
              </section>
              <!-- User Information -->
              <!-- Profile Sidebar -->
           <div class="progress-container progress-primary">
                  <span class="progress-badge">Inscritos : </span>
                  <span class="bold">{{evento.total_partis}} </span>
                  
          
              </div>  
              <section>
                <br />
                <tabs
                  v-model="accountTab"
                  vertical
                  tabNavWrapperClasses="col-md-12 text-center"
                  no-content-space
                >
                  <tab-pane label="Datos"></tab-pane>
                  <tab-pane label="Ficheros"></tab-pane>
                  <tab-pane label="Participantes"></tab-pane>
                  <tab-pane label="Firmas"></tab-pane>
                </tabs>
              </section>
              <!-- End Profile Sidebar -->
              <!-- Profile Completion -->
              <br /><br /><br />
              <!--  <div>
                <div class="progress-container progress-primary">
                  <span class="progress-badge">% Completado</span>
                  <base-progress :value="80" type="primary" showLabel>
                  </base-progress>
                </div>
              </div> -->

  
              <!-- End Profile Completion -->
            </div>
          </div>
          <div class="col-md-10 ml-auto">
            
            <div class="section">
              <div class="tab-content">
                <div v-if="accountTab === 'Datos'" class="tab-pane active">
                  <div>
                    <header>
                      <h2 class="text-uppercase">Datos del evento</h2>
                    </header>
                    <hr class="line-primary" />
                    <br />
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#firstName">Nombre</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="firstName"
                          name="firstName"
                          v-model="evento.nombre"
                          type="text"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#lastName">Fecha</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input addon-left-icon="ni ni-calendar-grid-58">
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{
                              allowInput: true,
                              dateFormat: 'Y-m-d H:i',
                              enableTime: true,
                              altInput: true,
                              altFormat: 'd/m/Y H:i',
                            }"
                            class="form-control datepicker"
                            v-model="evento.fecha"
                          >
                          </flat-picker>
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#lastName"
                          >Fecha inicio inscripción</label
                        >
                      </div>
                      <div class="col-md-4  align-self-center">
                        <base-input addon-left-icon="ni ni-calendar-grid-58">
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{
                              allowInput: true,
                              dateFormat: 'Y-m-d H:i',
                              enableTime: true,
                              altInput: true,
                              altFormat: 'd/m/Y H:i',
                            }"
                            class="form-control datepicker"
                            v-model="evento.inicioinscripcion"
                          >
                          </flat-picker>
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#lastName"
                          >Fecha fin inscripcion</label
                        >
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input addon-left-icon="ni ni-calendar-grid-58">
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{
                              allowInput: true,
                              dateFormat: 'Y-m-d H:i',
                              enableTime: true,
                              altInput: true,
                              altFormat: 'd/m/Y H:i',
                            }"
                            class="form-control datepicker"
                            v-model="evento.fininscripcion"
                          >
                          </flat-picker>
                        </base-input>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">Estado</label>
                      </div>
                      <div class="col-md-7  row  align-self-center">
                        <div class="col-md-6 align-self-center">
                          <label style="color:blue; " class="labels">{{
                            evento.estadostr
                          }}</label>
                        </div>
                        <div class="col-md-6 align-self-center">
                          <div class="form-group">
                            <choices-single
                              :options="estado"
                              v-model="evento.estado"
                            >
                              <option disabled value="0">Selecciona uno</option>
                            </choices-single>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#email">Descripción</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="email"
                          name="email"
                          type="text"
                          v-model="evento.descripcion"
                        ></base-input>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#location"
                          >Localización</label
                        >
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="location"
                          name="location"
                          type="text"
                          v-model="evento.localizacionnombre"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center text-bold">
                        <label class="labels" for="#phone"
                          >Participantes máximo</label
                        >
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input
                          id="maxpart"
                          name="phone"
                          type="tel"
                          v-model="evento.maxparticipantes"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone">Limitaciones</label>
                      </div>
                      <div class="col-md-8 align-self-center">
                        <base-input
                          id="limit"
                          name="phone"
                          type="tel"
                          v-model="evento.limitaciones"
                        ></base-input>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone">Sitio web</label>
                      </div>
                      <div class="col-md-8 align-self-center">
                        <base-input
                          id="web1"
                          name="phone"
                          type="tel"
                          v-model="evento.webevento"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#webfirma"
                          >Web de firma</label
                        >
                      </div>
                      <div class="col-md-8 align-self-center">
                        <base-input
                          id="webf"
                          name="webfirma"
                          type="tel"
                          v-model="evento.signerurl"
                        ></base-input>
                      </div>
                    </div>
                    <!--           <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">Plantillas de documentos</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="form-group">
                          <multiple-select
                            ref="selplantillas"
                            @input="onPlantillasChange"
                            @multiplevalues="getoptions"
                            :options="languages"
                            v-model="selectedtemplates"
                          >
                            <option disabled value="0">Select one</option>
                          </multiple-select>
                        </div>
                      </div>
                    </div> -->

                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone"
                          >Campos adicionales</label
                        >
                      </div>
                      <div class="col-md-8 align-self-center">
                        <base-input
                          id="limit"
                          name="phone"
                          type="tel"
                          v-model="evento.datosadicionales"
                        ></base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 align-self-center">
                        Documentos incluidos
                      </div>
                      <div class="col-md-6 align-self-left">
                        <ul
                          class="list-group list-group-flush"
                          data-toggle="checklist"
                        >
                          <li
                            class="px-0"
                            v-for="item in plantillas"
                            :key="item.referencia"
                          >
                            <div class="checklist-item">
                              <span>
                                                            <div class="col-md-2">
                                <base-checkbox v-model="item.plselected"  />
                              </div>
                              <div class="checklist-info col-md-8"> 
                                <small class="checklist-title mb-0">{{
                                  item.referencia
                                }} - {{item.nombre}}  <a style="color:red" v-bind:href="item.pdfurl"  >Descargar</a> </small> 
                              </div>
                             </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="fileinput fileinput-new text-center"
                        data-provides="fileinput"
                      >
                        <image-upload
                          type="avatar"
                          select-text="Foto del Evento"
                          @change="onAvatarChange"
                          btnClasses="btn-primary btn-sm btn-round mt-3"
                        />
                      </div>
                    </div>
                    <div class="row"></div>
                    <div class="row " v-if="false">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">Skills</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <tags value="VueJS, Angular, Laravel, React"></tags>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-md-10">
                        <base-button
                          nativeType="submit"
                          type="primary"
                          @click="updateEvento()"
                          >Guardar Cambios</base-button
                        >
                        <base-button nativeType="reset" outline type="primary"
                          >Cancelar</base-button
                        >
                        <base-button
                          nativeType="submit"
                          type="warning"
                          @click="cancelEvento()"
                          >Cancelar Evento</base-button
                        >

                        <base-button
                          v-if="evento.modolaptracker == 0"
                          nativeType="submit"
                          type="success"
                          @click="activarLaptracker()"
                          >Activar Gestión de Tiempos</base-button
                        >

                        <base-button
                          v-if="evento.modolaptracker != 0"
                          nativeType="submit"
                          type="success"
                          @click="gotoLaptracker()"
                          >Gestión de Tiempos</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="accountTab === 'Participantes'"
                  class="tab-pane active"
                >
                  <header>
                    <h2 class="text-uppercase">Participantes</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="container" style="margin-top: 250px;">
                    <div class="row">
                      <div class="col-12">
                        <lista-participantes></lista-participantes>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="accountTab === 'Firmas'" class="tab-pane active">
            
                  <hr class="line-primary" />
                  <br />
                  <div class="container" style="margin-top: 250px;">
                    <div class="row">
                      <div class="col-12">
                        <lista-firmas></lista-firmas>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="accountTab === 'Ficheros'" class="tab-pane active">
                    <h4 class="text-uppercase mb-0">Ficheros</h4>
              
                  <hr class="line-primary" />
                  <div>
                    <base-button
                      type="primary"
                      @click="modal0 = true"
                      v-if="mododatos == 2"
                    >
                      Ver Documento
                    </base-button>
                    <modal :show.sync="modal0" v-if="evento.pdf != null">
                      <template slot="header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Ver documento
                        </h5>
                      </template>
                      <div>
                        {{ currentPage }} / {{ pageCount }}
                        <button @click="page -= 1">PAG-</button>
                        <button @click="page += 1">PAG+</button>
                        <button @click="rotate += 90">&#x27F3;</button>
                        <button @click="rotate -= 90">&#x27F2;</button>
                        <button @click="$refs.pdf.print()">print</button>
                        <div style="width: 100%">
                          <div
                            v-if="loadedRatio > 0 && loadedRatio < 1"
                            style="background-color: green; color: white; text-align: center"
                            :style="{ width: loadedRatio * 100 + '%' }"
                          >
                            {{ Math.floor(loadedRatio * 100) }}%
                          </div>
                          <pdf
                            v-if="modal0"
                            ref="pdf"
                            style="border: 1px solid red"
                            :src="evento.pdf"
                            :page="page"
                            :rotate="rotate"
                            @password="password"
                            @progress="loadedRatio = $event"
                            @error="error"
                            @num-pages="pageCount = $event"
                            @page-loaded="currentPage = $event"
                            @link-clicked="page = $event"
                          ></pdf>
                        </div>
                      </div>
                      <template slot="footer">
                        <base-button type="secondary" @click="modal0 = false"
                          >Cerrar</base-button
                        >
                      </template>
                    </modal>
                  </div>
                  <div>
                    <base-button
                      type="primary"
                      class="mt-1"
                      @click="modal1 = true"
                      style="padding-top:5px"
                      v-if="evento.pdfadicional != null"
                    >
                      Ver Documento adicional
                    </base-button>
                    <modal :show.sync="modal1">
                      <template slot="header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Ver documento adicional
                        </h5>
                      </template>
                      <div>
                        {{ currentPage }} / {{ pageCount }}
                        <button @click="rotate += 90">&#x27F3;</button>
                        <button @click="rotate -= 90">&#x27F2;</button>
                        <button @click="$refs.pdf.print()">print</button>
                        <div style="width: 100%">
                          <div
                            v-if="loadedRatio > 0 && loadedRatio < 1"
                            style="background-color: green; color: white; text-align: center"
                            :style="{ width: loadedRatio * 100 + '%' }"
                          >
                            {{ Math.floor(loadedRatio * 100) }}%
                          </div>
                          <pdf
                            v-if="modal1"
                            ref="pdf"
                            style="border: 1px solid red"
                            :src="evento.pdfadicional"
                            :page="page"
                            :rotate="rotate"
                            @password="password"
                            @progress="loadedRatio = $event"
                            @error="error"
                            @num-pages="pageCount = $event"
                            @page-loaded="currentPage = $event"
                            @link-clicked="page = $event"
                          ></pdf>
                        </div>
                      </div>
                      <template slot="footer">
                        <base-button type="secondary" @click="modal1 = false"
                          >Cerrar</base-button
                        >
                      </template>
                    </modal>
                  </div>
                  <br />
                  <div>
                    <base-button
                      type="primary"
                      @click="downloadXls(evento.id)"
                      v-if="evento.xlsparticipantes != null"
                    >
                      Descargar Excel
                    </base-button>
                    <div class="col-md-5" style="padding:1px">
                      <base-button
                        @click="processXls()"
                        type="primary"
                        v-show="showProcesar"
                        >Procesar Excel</base-button
                      >
                      <span v-if="!showProcesar">{{ mensajeProcesar }}</span>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div class="d-flex">
                    <pdf
                      src=""
                      @num-pages="pageCount = $event"
                      @page-loaded="currentPage = $event"
                    ></pdf>
                  </div>

                  <div class="d-flex">
                    <!--                   <card class="col-lg-5 col-md-5">
                      <h3 slot="header" class="mb-0">Imagenes</h3>

                      <dropzone-file-upload
                        v-model="inputs.fileMultiple"
                        multiple
                      ></dropzone-file-upload>
                    </card> -->
                    <card class="col-lg-3 col-md-3" v-if="mododatos == 2">
                      <!--Card Header-->
                      <h3 slot="header" class="mb-0">Documento</h3>
                      <!--Card Body-->

                      <pdf-dropzone-file-upload
                        v-model="inputs.fileSingle"
                        @changeFile="fileAdded($event, 1)"
                        v-if="mododatos == 2"
                      ></pdf-dropzone-file-upload>
                      <base-button
                        type="primary"
                        @click="updateFichero(1)"
                        v-if="readerpdf.length > 0"
                      >
                        Guardar
                      </base-button>
                    </card>
                    <div class="col-lg-1 col-md-1"></div>

                    <card class="col-lg-3 col-md-3" v-if="mododatos == 2">
                      <!--Card Header-->
                      <h3 slot="header" class="mb-0">Documento adicional</h3>
                      <!--Card Body-->

                      <pdf-dropzone-file-upload
                        v-model="inputs.fileSingle"
                        @changeFile="fileAdded($event, 3)"
                        v-if="mododatos == 2"
                      ></pdf-dropzone-file-upload>
                      <base-button
                        type="primary"
                        @click="updateFichero(3)"
                        v-if="readerpdfadic.length > 0"
                      >
                        Guardar
                      </base-button>
                    </card>
                    <div class="col-lg-1 col-md-1"></div>

                    <card class="col-lg-3 col-md-3">
                      <!--Card Header-->
                      <h3 slot="header" class="mb-0">Excel</h3>
                      <!--Card Body-->

                      <xls-dropzone-file-upload
                        v-model="inputs.fileXlsSingle"
                        @changeFileXls="fileXlsAdded($event)"
                      ></xls-dropzone-file-upload>
                      <base-button
                        type="primary"
                        @click="updateFichero(2)"
                        v-if="readerxls.length > 0"
                      >
                        Guardar
                      </base-button>
                    </card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modalerror">
      <div class="modal-content bg-gradient-danger">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-notification">
            Ha ocurrido un error
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="py-3 text-center">
            <i class="ni ni-bell-55 ni-3x"></i>
            <h4 class="heading mt-4">Error al actualizar evento!</h4>
            <p></p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-white"
            @click="modalerror = false"
          >
            Cerrar
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import ImageUpload from "@/components/ImageUpload";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import ChoicesSingle from "@/components/SingleSelect";
import MultipleSelect from "@/components/MultipleSelect";
import Tags from "@/components/Tags";
import BaseAlert from "@/components/BaseAlert";
import DropzoneFileUpload from "@/views/components/JavascriptComponents/FileUpload";
import PdfDropzoneFileUpload from "@/views/components/JavascriptComponents/PdfFileUpload";
import XlsDropzoneFileUpload from "@/views/components/JavascriptComponents/XlsFileUpload";
import pdf from "vue-pdf-signature";
import axios from "axios";
import Modal from "@/components/Modal";
import UserService from "../services/user.service";
import ListaParticipantes from "./ListaParticipantes.vue";
import Spinner from "../components/Spinner.vue";
import authHeader from "../services/auth-header";
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ListaFirmas from "./ListaFirmas.vue";
import userService from "../services/user.service";
import { Select, Option } from "element-ui";

export default {
  name: "EventoDetail",
  props: ["id"],
  bodyClass: "account-settings",
  components: {
    ImageUpload,
    Tabs,
    TabPane,
    ChoicesSingle,
    //  MultipleSelect,
    Tags,
    //BaseAlert,
    //   DropzoneFileUpload,
    PdfDropzoneFileUpload,
    XlsDropzoneFileUpload,
    pdf,
    Modal,
    ListaParticipantes,
    Spinner,
    flatPicker,
    ListaFirmas,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  data() {
    return {
      uuid: "",
      accountTab: "Datos",
      isLoading: true,
      evento: {},
      oldevento: {},
      selected: 2,
      selectedtemplates: [],
      modal0: false,
      modal1: false,
      modalerror: false,
      mododatos: 0,
      showProcesar: false,
      mensajeProcesar: "--",
      images: {
        avatar: null,
      },
      inputs: {
        fileSingle: [],
        fileXlsSingle: [],
        fileMultiple: [],
      },
      readerpdf: "",
      readerpdfadic: "",
      readerxls: "",
      readerimg: "",
      currentPage: 0,
      pageCount: 0,
      page: 1,
      rotate: 0,
      progress: 0,
      estado: [
        { id: 1, text: "Programado" },
        { id: 10, text: "En curso" },
        { id: 20, text: "Finalizado" },
        { id: 30, text: "Cancelado" },
      ],

      languages: [{ id: 1, text: "-----" }],
      plantillas: [],
      questions: [
        { id: 1, text: "Question 1" },
        { id: 2, text: "Question 2" },
        { id: 3, text: "Question 3" },
      ],
      switches: {
        off: false,
        on: true,
      },
      radio: {
        radio1: "radio1",
      },
    };
  },
  mounted() {
    this.uuid = this.$route.params.id;
    this.$store.commit("setEventouuid", this.uuid);
    this.loadData();
  },

  methods: {
    loadData() {
      const self = this;
      self.isLoading = true;
      this.getplantillas();
      let url = process.env.VUE_APP_BASE_URI + "api/v1/evento/" + this.uuid;
      self.$http.get(url).then(
        function(success) {
          self.evento = success.data;
          self.evento.fmtfininscripcion = moment(
            self.evento.fininscripcion
          ).format("DD/MM/YYYY");
          self.oldevento = JSON.parse(JSON.stringify(success.data));
          self.mododatos = self.evento.modogeneracion;
          self.evento = success.data;
          if (self.evento.xlsprocesado) {
            self.showProcesar = false;
            self.mensajeProcesar = "No hay Excel pendiente de procesar";
          }
          let obj = self.estado.find((o) => o.id === self.evento.estado);
          self.evento.estadostr = obj.text;
          // self.getplantillas();
          self.isLoading = false;

          for (var i = 0; i < self.plantillas.length; i++) {
            if (
              self.evento.plantillas.indexOf(self.plantillas[i].referencia) > -1
            ) {
              self.plantillas[i].plselected = true;
            }
          }
          //   self.getUserData(id1);
        },
        function(error) {
          self.isLoading = false;
          self.snacktext = "Error al cargar datos del evento  ";
          self.snackcolor = "error";
          self.snackresult = true;
        }
      );
    },
    fileAdded(file, tipo) {
      let vv = file;
      if (file == null) {
        this.inputs.fileSingle = null;
        this.readerpdf = "";
        return;
      }
      const self = this;
      this.inputs.fileSingle = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.inputs.fileSingle);
      reader.onload = () => {
        console.log(reader.result);
        if (tipo == 3) {
          self.readerpdfadic = reader.result;
        } else {
          self.readerpdf = reader.result;
        }
      };
    },
    updateEvento() {
      var changes = {};
      const API_URL = process.env.VUE_APP_BASE_URI + "api/v1/";
      //  var opts = this.$refs.selplantillas.$refs.select.options;
      var plantillasstr = "";
      //  for (var j = 0; j < opts.length; j++) {
      //     var opt1 = opts[j].innerText;
      //     plantillasstr = plantillasstr + opt1;
      //     plantillasstr = plantillasstr + ",";
      //   }
      for (var j = 0; j < this.plantillas.length; j++) {
        if (this.plantillas[j].plselected == true) {
          plantillasstr = plantillasstr + this.plantillas[j].referencia;
          plantillasstr = plantillasstr + ",";
        }
      }
      const keys1 = Object.keys(this.evento);
      const keys2 = Object.keys(this.oldevento);
      for (let key of keys1) {
        if (this.evento[key] !== this.oldevento[key]) {
          changes[key] = this.evento[key];
          if( key.includes('max')){
            changes[key] = parseInt(this.evento[key]);
          }
          //  if( key.includes('fecha') || key.includes('inscripcion'))
          //    changes[key] = this.evento[key]+"T12:00"
        }
      }
      changes["plantillas"] = plantillasstr;
      const self = this;
      console.log(changes);
      axios
        .patch(API_URL + "evento/" + this.evento["id"] + "/", changes, {
          headers: authHeader(),
        })
        .then(
          (response) => {
            if (response.data) {
              console.log(response);
              swal.fire({
                title: `Evento modificado`,
                text: `Datos de evento modificados...`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-primary",
              });
              self.evento = response.data;
              self.evento.fmtfininscripcion = moment(
                self.evento.fininscripcion
              ).format("DD/MM/YYYY");
              self.oldevento = JSON.parse(JSON.stringify(response.data));
              self.mododatos = self.evento.modogeneracion;
              self.evento = success.data;
              if (self.evento.xlsprocesado) {
                self.showProcesar = false;
                self.mensajeProcesar = "No hay Excel pendiente de procesar";
              }
              let obj = self.estado.find((o) => o.id === self.evento.estado);
              self.evento.estadostr = obj.text;
            }
          },
          (error) => {
            self.modalerrorr = true;

            return Promise.reject(error);
          }
        );
    },
    cancelEvento() {
      swal
        .fire({
          title: "Estás seguro?",
          text: "El evento será cancelado!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonnText: "Interrumpir",
          confirmButtonText: "Cancelar Evento",
        })
        .then((result) => {
          if (result.value) {
            var changes = {};
            const API_URL = process.env.VUE_APP_BASE_URI + "api/v1/";

            const keys1 = Object.keys(this.evento);
            const keys2 = Object.keys(this.oldevento);
            for (let key of keys1) {
              if (this.evento[key] !== this.oldevento[key]) {
                changes[key] = this.evento[key];
                //  if( key.includes('fecha') || key.includes('inscripcion'))
                //    changes[key] = this.evento[key]+"T12:00"
              }
            }
            const self = this;
            console.log(changes);
            axios
              .post(API_URL + "cancelevento/?evento=" + this.evento["id"], {
                headers: authHeader(),
              })
              .then(
                (response) => {
                  if (response.data) {
                    console.log(response);
                    swal.fire(
                      {
                        title: `Evento Cancelado`,
                        text: `Datos de evento eliminados...`,
                        buttonsStyling: false,
                        confirmButtonClass: "btn btn-primary",
                      },
                      (error) => {
                        swal.fire({
                          title: `Error Cancelado`,
                          text: `Se ha producido un error cancelando el evento.`,
                          buttonsStyling: false,
                          confirmButtonClass: "btn btn-primary",
                        });
                        return Promise.reject(error);
                      }
                    );

                    this.$router.push({
                      path: "/listaeventos2",
                    });
                  }
                },
                (error) => {
                  self.modalerrorr = true;

                  return Promise.reject(error);
                }
              );
          }
        });
    },

    fileXlsAdded(file) {
      let vv = file;
      if (file == null) {
        this.inputs.fileXlsSingle = null;
        this.readerxls = "";
        return;
      }
      const self = this;
      this.inputs.fileXlsSingle = file;
      var reader = new FileReader();
      reader.readAsDataURL(this.inputs.fileXlsSingle);
      reader.onload = () => {
        console.log(reader.result);
        self.readerxls = reader.result;
        //      self.showProcesar =true;
        //              swal.fire({
        //     title: 'Fichero añadido',
        //     text: 'Se ha añadido el fichero.',
        //     buttonsStyling: false,
        //      confirmButtonClass: 'btn btn-warning',
        //     icon: 'success'
        //   });
      };
    },

    updateFichero: function(tipo) {
      var datos = "";
      const self = this;

      if (tipo == 1) {
        datos = self.readerpdf;
        if (this.readerpdf.split(",")[0].indexOf("base64") >= 0) {
          datos = this.readerpdf.split(",")[1];
        }
      } else if (tipo == 3) {
        datos = self.readerpdfadic;
        if (this.readerpdfadic.split(",")[0].indexOf("base64") >= 0) {
          datos = this.readerpdfadic.split(",")[1];
        }
      } else if (tipo == 2) {
        datos = self.readerxls;
        if (this.readerxls.split(",")[0].indexOf("base64") >= 0) {
          datos = this.readerxls.split(",")[1];
        }
      }

      var data = {};
      if (tipo == 1) {
        data = {
          evento: this.uuid,
          tipofichero: "plantilla",
          file: datos,
        };
      } else if (tipo == 3) {
        data = {
          evento: this.uuid,
          tipofichero: "plantilla2",
          file: datos,
        };
      } else if (tipo == 2) {
        data = {
          evento: this.uuid,
          tipofichero: "xlsparticipantes",
          file: datos,
        };
      }
      self.isLoading = true;

      UserService.updatefileinevento(data).then(
        (user) => {
          swal.fire({
            title: "Fichero añadido",
            text: "Se ha añadido el fichero.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "success",
          });
          self.showProcesar = true;
          self.readerpdf = "";
          self.readerpdfadic = "";
          self.isLoading = false;
          self.readerxls = "";
        },
        (error) => {
          swal.fire({
            title: "Fichero no añadido",
            text: "Error al añadir el fichero.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-error",
            icon: "error",
          });
          self.isLoading = false;
          self.readerxls = "";
        }
      );
    },
    updateImagen() {
      const self = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.images.avatar);
      reader.onload = () => {
        self.readerimg = reader.result;
        var datos = self.readerimg;
        if (self.readerimg.split(",")[0].indexOf("base64") >= 0) {
          datos = self.readerimg.split(",")[1];
        }
        var data = {
          evento: this.uuid,
          tipofichero: "image1",
          file: datos,
        };
        UserService.updateimageninevento(data).then(
          (user) => {
            swal.fire({
              title: "Fichero añadido",
              text: "Se ha añadido el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "success",
            });
            self.isLoading = false;
          },
          (error) => {
            swal.fire({
              title: "Fichero no añadido",
              text: "Error al añadir el fichero.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-error",
              icon: "error",
            });
            self.isLoading = false;
            self.readerxls = "";
          }
        );
      };
    },
    gotoFicheros: function(uuid) {
      this.$router.push({
        path: "/addficheros/:id",
        query: { id: uuid },
      });
    },
    gotoEventos: function(uuid) {
      this.$router.push({
        path: "/listaeventos2",
        query: { id: uuid },
      });
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images.avatar = file;
      this.updateImagen();
    },
    onPlantillasChange(e) {
      alert(e);
    },
    getoptions(e) {
      alert(e);
    },
    gotoLaptracker: function(uuid) {
      const self = this;
      UserService.getRodadaFromEvento(this.uuid).then(
        (response) => {
          var rodada = response.data.results[0];
          this.$router.push({
            path: "/rodada/" + rodada.id,
            query: { id: rodada.id },
          });
        },
        (error) => {
          alert("Error");
        }
      );
    },
    forceFileDownload(response, title) {
      console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    downloadXls(eventuuid) {
      const self = this;

      UserService.getXls(eventuuid).then(
        (data) => {
          self.forceFileDownload(data, "participantes.xls");
        },
        (error) => {
          alert("Error");
        }
      );
    },
    processXls() {
      const self = this;
      self.isLoading = true;
      // let uuid = this.$store.getters.uuid;
      let url =
        process.env.VUE_APP_BASE_URI +
        "api/v1/processxls?evento=" +
        this.$store.getters.eventouuid;
      self.$http.get(url).then(
        function(success) {
          var numcargados = success.data;
          let url2 =
            process.env.VUE_APP_BASE_URI +
            "api/v1/participacion?evento_id=" +
            self.$store.getters.eventouuid;
          self.$http.get(url2).then(
            function(success) {
              self.participaciones = [];
              var participa = success.data.results;
              var linea = {};
              for (var i = 0; i < participa.length; i++) {
                linea.id = participa[i].id;
                linea.email = participa[i].customer.user.email;
                linea.dni = participa[i].customer.dni;
                linea.telefono = participa[i].customer.telefono;
                linea.seguro = participa[i].customer.seguro;
                linea.licencia = participa[i].customer.licencia;
                linea.nombre = participa[i].customer.user.first_name;
                linea.apellidos = participa[i].customer.user.last_name;
                linea.state = participa[i].state;
                if (linea.state == 0) {
                  linea.strstate = "PENDIENTE";
                }
                if (linea.state == 1) {
                  linea.strstate = "ACCESO";
                }
                if (linea.state == 2) {
                  linea.strstate = "FIRMADA";
                }
                if (linea.state > 2) {
                  linea.strstate = "CANCELADA";
                }
                self.participaciones.push(linea);
              }

              self.isLoading = false;
              swal.fire({
                title: "Correcto!",
                text: "Se ha procesado el fichero correctamente. Se han creado " + numcargados + " nuevas participaciones",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "success",
              });
            },
            function(error) {
              self.isLoading = false;
              swal.fire({
                title: "Error!",
                text: "Error al procesar fichero.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "warning",
              });
            }
          );
        },
        function(error) {
          self.isLoading = false;
          swal.fire({
            title: "Error!",
            text: "Error al procesar fichero.",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            icon: "warning",
          });
        }
      );
    },
    activarLaptracker() {
      var changes = {};
      const API_URL = process.env.VUE_APP_BASE_URI + "api/laptracker/";

      const keys1 = Object.keys(this.evento);
      const keys2 = Object.keys(this.oldevento);

      var data = {
        evento: this.evento["id"],
      };

      const self = this;
      axios
        .post(API_URL + "activatelaptracker/", data, {
          headers: authHeader(),
        })
        .then(
          (response) => {
            if (response.data) {
              console.log(response);
              swal.fire({
                title: `Evento modificado`,
                text: `Activada gestión de tiempos para  este evento ...`,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-primary",
              });
              self.evento.modolaptracker = 1;
              let obj = self.estado.find((o) => o.id === self.evento.estado);
              self.evento.estadostr = obj.text;
            }
          },
          (error) => {
            self.modalerrorr = true;

            return Promise.reject(error);
          }
        );
    },
    getplantillas() {
      const self = this;

      UserService.getPlantillas().then(
        (response) => {
          var opts = response.data;
          self.v1 = "sssssssss";
          for (var i = 0; i < response.data.results.length; i++) {
            self.languages[i] = {};
            self.languages[i].id = response.data.results[i].referencia;
            self.languages[i].text = response.data.results[i].referencia;
            var entplant = {
              referencia: response.data.results[i].referencia,
              nombre:  response.data.results[i].nombre,
              pdfurl:  response.data.results[i].pdf,
              plselected: false,
            };
            self.plantillas.push(entplant);
          }
          //this.$refs.selplantillas.setChoices();

          for (var i = 0; i < self.plantillas.length; i++) {
            if (
              self.evento.plantillas != null &&
              self.evento.plantillas.indexOf(self.plantillas[i].referencia) > -1
            ) {
              self.plantillas[i].plselected = true;
            }
          }
        },
        (error) => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          // alert(this.content);
        }
      );
    },
  },
};
</script>
<style>
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
}
</style>
