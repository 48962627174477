<template>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <small class="d-block text-uppercase font-weight-bold mb-3"
        >Excel Participantes</small
      >
            <small class="d-block text-uppercase font-weight-bold mb-3"
        >{{filename}}</small
      >
      <xls-upload @change="onImageChange" select-text="Elige Fichero Excel"  remove-text="Eliminar" change-text="Cambiar"/>
    </div>

  </div>
</template>
<script>
import XlsUpload from "@/components/XlsUpload";
export default {
  components: {
    XlsUpload
  },
  data(){
    return {
      filename: "",

    }
  },
  methods: {
    onImageChange(file) {
      if( file !== null ){
        this.filename=file.name
        this.$emit('changeFileXls', file)
      }
      else{
        this.$emit('changeFileXls', null)
      }
 
    }
  }
};
</script>
<style></style>
