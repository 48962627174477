<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('img/sections/unsplashs.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">SIGN AND RUN</h1>
                <p class="text-lead text-white"></p>
                <p class="text-lead text-white"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="upper">
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-2">
                <small
                  >Para finalizar la firma de la documentación usa la clave que
                  has recibido por SMS</small
                >
              </div>
              <form role="form">
                <div class="form-group mb-3">
                  <base-input
                    alternative
                    placeholder="OTP"
                    addonLeftIcon="ni ni-email-83"
                    v-model="otp"
                    v-validate="'required|min:4|max:40'"
                  ></base-input>
                </div>

                <div class="text-center">
                  <base-button type="primary" class="my-2" @click="checkOtp"
                    >Verificar código</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="d-flex justify-content-center mb-3 modal "
      style="padding-top:300px"
      v-if="isLoading"
    >
      <spinner label="Loading..." style="width: 3rem; height: 3rem;"></spinner>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
import jwt_decode from "jwt-decode";
import UserService from "../services/user.service";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import Spinner from "../components/Spinner.vue";

export default {
  name: "Login",
  bodyClass: "login-page",
  otp: "",
  components: {
    Spinner,
  },

  data() {
    return {
      user: new User("", ""),
      isLoading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      //   this.$router.push('/account');
    }
  },
  methods: {
    checkOtp() {
      let self = this;
      this.isLoading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
         // self.loading = false;
          //return;
        }

        var otpInfo = {
          otpValue: self.otp,
          operationUuid: self.$store.state.opuuid,
          otpType: "FIRMA",
        };

        self.$http.defaults.headers.common.Authorization = this.$store.getters.token;

        self.$http
          .post(
            process.env.VUE_APP_FIRMA_BASEURL +
              "verifyotpfull/" +
              this.$store.state.opuuid,
            otpInfo
          )
          .then(
            (response) => {
              if (response.status < 300) {
                self.loading = false;
                console.log(response);
                swal.fire(
                  {
                    title: `Código validado`,
                    text: ``,
                    buttonsStyling: false,
                    confirmButtonClass: "btn btn-primary",
                  },
                  (error) => {
                    self.loading = false;
                    swal.fire({
                      title: `Error `,
                      text: `Código incorrecto.`,
                      buttonsStyling: false,
                      confirmButtonClass: "btn btn-primary",
                    });
                    return Promise.reject(error);
                  }
                );
                self.isLoading = false;
                swal.fire({
                  title: "Firma completada!",
                  text: "Has finalizado la firma de documentación.",
                  buttonsStyling: false,
                  confirmButtonClass: "btn btn-success",
                  icon: "success",
                });
                this.$router.push({
                  path: "/finalizado",
                });
              }
            },
            (error) => {
              self.modalerrorr = true;
              self.isLoading = false;
              swal.fire({
                title: "Error!",
                text: "No se ha podido verificar la clave de firma.",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning",
                icon: "warning",
              });
              return Promise.reject(error);
            }
          );
      });
    },
  },
};
</script>

<style></style>
