<template>
  <div
    class="overlay"
  >
    <div class="modal">
      <div class="modal__title">
        <span>Session Expired</span>
      </div>
      <div class="p-3"> 
    		<p>You have left this browser idle for 10 minutes.</p>
    		<p>{{ time }} second left</p>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
export default {
	data() {
		return {
			time: 10000
		}
	},
	created() {
        const self=this;
                if(         this.$store.getters.isLogged == false){
        return false;

        }
        this.$store.commit("setIsLogged", false);
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);

        // Your logout function should be over here
                    swal.fire({
              title: "Desconexión",
              text: "Inactividad.",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning",
              icon: "warning",
            });
        self.$store.commit("setIsLogged", false);

        self.$router.push({
        path: "/",
        });
      }
    }, 1000);
  }
};
</script>